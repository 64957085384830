import * as React from 'react';


import './PreRegistrationFormPage.scss';

import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";
import { AppSettings } from '../../../helpers/AppSettings';

import { flow } from "lodash";

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as PreRegistrationFormActions from '../../../actions/forms/preRegistrationForm';
import * as PreRegistrationFormPageActions from '../../../actions/pages/registration/preRegistrationFormPage';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { IPreRegistrationReduxFormState, initialPreRegistrationFormState } from "../../../reducers/reactReduxForms/preRegistrationForm";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, ValidationMessage, DatePickerFormInputFabric, CheckboxFormInput, GenericMultiFormGroup, NumberFormInput } from "../../../components/form/index";
import { mapProps, showErrors, validateOn } from "../../../helpers/reactReduxFormSettings";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import * as Moment from 'moment';
import { STANDARD_INTERNATIONAL_CODING_SYSTEM, PATIENT_INITIALS } from "../../../constants/inputMasks";
import { findFieldValidationFailures } from "../../../helpers/formErrorHelper";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import { NotificationAlert, MessageModal } from "../../../components/common/index";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { IRegistrationElement, RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { GridRemove } from "../../../enumerations/GridRemove";
import { requiredText, requiredSourceDocument, validateByRegex, validationRegex, errorMessages} from "../../../validators/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock, renderRegistrationFormSaveHard } from "../../../helpers/alert";
import { SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { initialSourceDocumentState, ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import { ISoftError } from '../../../interfaces/forms/ISoftError';
import * as WebsiteSettingsActions from '../../../actions/websiteSettings';

interface IPreRegistrationFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface IPreRegistrationFormPageActions {
    loadPreRegistrationForm: PreRegistrationFormActions.IPreRegistrationFormLoadByPatientIdentifierActionCreator,
    savePreRegistrationForm: PreRegistrationFormActions.IPreRegistrationFormSaveActionCreator,
    createPreRegistrationForm: PreRegistrationFormActions.IPreRegistrationFormCreateActionCreator,
    clearPreRegistrationForm: PreRegistrationFormActions.IPreRegistrationFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    loadSettings: WebsiteSettingsActions.IWebsiteSettingsLoadActionCreator;

    toggleMessageModal: PreRegistrationFormPageActions.IPreRegistrationFormPageToggleMessageModalActionCreator;
    notifySetERStatus: PreRegistrationFormPageActions.IPreRegistrationFormPageNotifySetERStatusActionCreator;
}

interface IPreRegistrationFormPageProps {

    form: Dtos.PreRegistrationForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: IPreRegistrationReduxFormState;

    location: Location;
    match: match<IPreRegistrationFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    preRegistrationForm: Dtos.PreRegistrationForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingPreRegistrationForm: boolean;
    loadPreRegistrationFormSuccess: boolean;
    loadPreRegistrationFormFailure: boolean;

    savingPreRegistrationForm: boolean;
    savePreRegistrationFormSuccess: boolean;
    savePreRegistrationFormFailure: boolean;

    creatingPreRegistrationForm: boolean;
    createPreRegistrationFormSuccess: boolean;
    createPreRegistrationFormFailure: boolean;

    messageModalOpen: boolean;

    websiteSetting: Dtos.WebsiteSetting;

    loadingSettings: boolean;
    loadingSettingsSuccess: boolean;
    loadingSettingsFailure: boolean;

}

type PreRegistrationFormPageProps = IPreRegistrationFormPageProps & IPreRegistrationFormPageActions;

const formName: string = "PreRegistrationForm";
const reduxFormName: string = "reduxForms.preRegistrationForm";


class PreRegistrationFormPage extends React.PureComponent<PreRegistrationFormPageProps, any> {

    constructor(props: PreRegistrationFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearPreRegistrationForm = this.clearPreRegistrationForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadPreRegistrationForm,
            createPreRegistrationForm,
            loadSettings
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadPreRegistrationForm(identifier);
        }
        else if (!identifier) {
            createPreRegistrationForm();
        }

        if (institutionCode) {
            loadInstitution(institutionCode);
        }
        loadSettings();
    }

    componentWillReceiveProps(nextProps: PreRegistrationFormPageProps) {
    }

    componentDidUpdate(prevProps: PreRegistrationFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingPreRegistrationForm,
            loadPreRegistrationFormSuccess,
            loadPreRegistrationFormFailure,
            savingPreRegistrationForm,
            savePreRegistrationFormSuccess,
            savePreRegistrationFormFailure,
            creatingPreRegistrationForm,
            createPreRegistrationFormSuccess,
            createPreRegistrationFormFailure,
            createPreRegistrationForm,
            preRegistrationForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            toggleMessageModal
        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    //Send email here
                    PreRegistrationFormPageActions.notifySetERStatus();
                    
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/pre-registration-form");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createPreRegistrationForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }
              
        if (!loadingPreRegistrationForm && prevProps.loadingPreRegistrationForm) {
            if (loadPreRegistrationFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingPreRegistrationForm && prevProps.savingPreRegistrationForm) {
            if (savePreRegistrationFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {
                    //About to be changed to Pre-registration status? (Send notification email for ER Status @ Screening Log to be completed)
                    if (patient && patient.status == Dtos.PatientStatus.PreEntry)
                        this.PreRegister();

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    this.loadForm();
                }

                if (!identifier) {

                    loadPatientById(preRegistrationForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={this.renderErrorAlert()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPreRegistrationForm && prevProps.creatingPreRegistrationForm) {
            if (createPreRegistrationFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    loadForm() {
        const {
            loadForm,
            preRegistrationForm
        } = this.props

        let extraData: Dtos.PreRegistrationForm = {} as Dtos.PreRegistrationForm;

        loadForm(reduxFormName, Object.assign({ ...preRegistrationForm }, extraData));
    }

    componentWillUpdate(nextProps: PreRegistrationFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearPreRegistrationForm();
        loadForm(reduxFormName, initialPreRegistrationFormState);
        resetForm(reduxFormName);
    }

    PreRegister() {
        const {
            patient,
            identifier,
            institution,
            notifySetERStatus
        } = this.props

        notifySetERStatus();
    }

    render() {

        const {
            loadingPatient,
            savingPreRegistrationForm,
            loadingInstitution,
            loadingPreRegistrationForm,
            loadPreRegistrationFormSuccess,
            creatingPreRegistrationForm,
            createPreRegistrationFormSuccess,
        } = this.props

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingPreRegistrationForm || creatingPreRegistrationForm}
            saving={savingPreRegistrationForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }
    
    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadPreRegistrationFormSuccess,
            createPreRegistrationFormSuccess,
            savePreRegistrationFormSuccess,
            reduxFormState,
            formState,
            messageModalOpen,
            toggleMessageModal,
            notifySetERStatus,
            savingPreRegistrationForm,
            formProperties,
            changeForm,
            websiteSetting
        } = this.props;

        const group2Enabled: boolean = websiteSetting && websiteSetting.enableGroup2;
        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingPreRegistrationForm;
        let canSetGroup:boolean = false;
        if (patient && patient.status && patient.status < Dtos.PatientStatus.Registered) {
            canSetGroup = true;
        }
        
        let groupOptions = generateOptionsFromLookup(findLookup("PreRegistrationForm", "RegistrationGroup", lookups));
        if (!group2Enabled) {
            if (form.registrationGroup == undefined || form.registrationGroup == 0) {
                // show the options, just make group 2 not selectable
                groupOptions = groupOptions.filter(f => f.value != 1);
            }
        }

        const {
            medicareConsentNo
        } = this.handleDisabledValues();
        
        const formComponents: { [index: string]: RegistrationFormComponent } = {
            patientInitials: {
                type: RegistrationComponent.RegistrationControl,
                inputType: MaskedFormInput,
                inputProps: {
                    mask: PATIENT_INITIALS
                },
                inputDisabled: formDisabled,
            }, 
            gender: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PreRegistrationForm", "Gender", lookups))
                }
            },  
            dateOfBirth: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,               
                inputProps: {
                    mask: PATIENT_INITIALS
                },
            },
            ageAtConsent: {
                type: RegistrationComponent.RegistrationControl,
                inputType: NumberFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    maxLength: 2,
                    onKeyPress: (event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }
                },
            },
            registrationGroup: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: groupOptions
                },
            }, 
            medicalOncologistName: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            radiationOncologistName: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            consentHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h2>Informed Consent</h2>
                </div>
            },    
            dateWrittenInformedConsent: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,               
                inputProps: {
                    maxDate: new Date()
                },
            }, 
            medicareConsent: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PreRegistrationForm", "MedicareConsent", lookups))
                },
            },     
            dateOfServicesAustraliaDataConsent: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled || medicareConsentNo,               
                inputProps: {
                    maxDate: new Date()
                },
            },
            consentDetails: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || medicareConsentNo,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PreRegistrationForm", "ConsentDetails", lookups))
                },
            },  
            startDateOfDataCollection: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled || medicareConsentNo ,               
                inputProps: {
                    maxDate: new Date()
                },
            }, 
            stopDateOfDataCollection: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled || medicareConsentNo,               
                inputProps: {
                    //maxDate: new Date()
                },
            }
        }

        return <div>
            <h1>Participant Details</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "preRegistrationForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2">Cancel</button>
                </div>
            </Form>
        </div >
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearPreRegistrationForm() {
        this.props.clearPreRegistrationForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = 'New Patient'
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Participant Details"
            }
        ]
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong>not</strong> saved. Please ensure all data is valid and try again.
        </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        let medicareConsentNo = true;
        if (form.medicareConsent != undefined && form.medicareConsent == 0) {
            medicareConsentNo = false;
        }

        return {
            medicareConsentNo
        }
    }


    handleOnSubmit(data: Dtos.PreRegistrationForm) {

        const {
            savePreRegistrationForm,
            institutionCode,
            identifier,
            validationFailures
        } = this.props;

        if (data && institutionCode) {

            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.PreRegistrationForm = {} as Dtos.PreRegistrationForm;

            //if (isDisabled.dateSignedPreScreeningConsentFormDisabled) {
            //    changes.dateSignedConsentForm = null
            //}
            savePreRegistrationForm(Object.assign({ ...data }, changes), institutionCode, identifier);
        }

    }

    handleOnSubmitFailed(data: Dtos.PreRegistrationForm) {

        const { institutionCode,
            identifier,
            formProperties
        } = this.props;

        //let errorMessage: string = 'Fields to check: ';

        //Object.keys(data).forEach(key => {

        //    if (data[key]) {
        //        if (!data[key]['valid']) {
        //            errorMessage = errorMessage + data[key];
        //        }

        //    }

        //});

        //console.log(errorMessage);

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveHard()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props
        // eslint-disable-next-line
        toggleMessageModal;
    }
}

const mapStateToProps = (state: IFigState, ownProps: PreRegistrationFormPageProps): IPreRegistrationFormPageProps => {
    // eslint-disable-next-line
    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined!,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined!,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined!,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined!,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        preRegistrationForm: !(state.registrationForms.preRegistrationForms.formData instanceof Array) ? state.registrationForms.preRegistrationForms.formData : undefined!,
        lookups: state.registrationForms.preRegistrationForms.lookups,
        loadingPreRegistrationForm: state.registrationForms.preRegistrationForms.loadState &&
            (state.registrationForms.preRegistrationForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.preRegistrationForms.loadState.status == RequestState.None &&
                    state.registrationForms.preRegistrationForms.createState.status == RequestState.None)),
        loadPreRegistrationFormSuccess: state.registrationForms.preRegistrationForms.loadState && state.registrationForms.preRegistrationForms.loadState.status == RequestState.Success,
        loadPreRegistrationFormFailure: state.registrationForms.preRegistrationForms.loadState && state.registrationForms.preRegistrationForms.loadState.status == RequestState.Failure,

        savingPreRegistrationForm: state.registrationForms.preRegistrationForms.saveState && state.registrationForms.preRegistrationForms.saveState.status == RequestState.Pending,
        savePreRegistrationFormSuccess: state.registrationForms.preRegistrationForms.saveState && state.registrationForms.preRegistrationForms.saveState.status == RequestState.Success,
        savePreRegistrationFormFailure: state.registrationForms.preRegistrationForms.saveState && state.registrationForms.preRegistrationForms.saveState.status == RequestState.Failure,

        creatingPreRegistrationForm: state.registrationForms.preRegistrationForms.createState && state.registrationForms.preRegistrationForms.createState.status == RequestState.Pending,
        createPreRegistrationFormSuccess: state.registrationForms.preRegistrationForms.createState && state.registrationForms.preRegistrationForms.createState.status == RequestState.Success,
        createPreRegistrationFormFailure: state.registrationForms.preRegistrationForms.createState && state.registrationForms.preRegistrationForms.createState.status == RequestState.Failure,

        form: state.reduxForms.preRegistrationForm,
        formState: state.registrationForms.preRegistrationForms.formState,
        formProperties: state.registrationForms.preRegistrationForms.formProperties,

        reduxFormState: state.reduxForms.formState.preRegistrationForm,
        validationFailures: (state.registrationForms.preRegistrationForms.saveState.status == RequestState.Success || state.registrationForms.preRegistrationForms.saveState.status == RequestState.Failure) ?
            state.registrationForms.preRegistrationForms.saveState &&
                state.registrationForms.preRegistrationForms.saveState.errors ?
                state.registrationForms.preRegistrationForms.saveState.errors :
                undefined! :
            state.registrationForms.preRegistrationForms.loadState &&
                state.registrationForms.preRegistrationForms.loadState.errors ?
                state.registrationForms.preRegistrationForms.loadState.errors :
                undefined!,

        messageModalOpen: state.preRegistrationFormPage.messageModalOpen,
        websiteSetting: !(state.websiteSettings.data instanceof Array) ? state.websiteSettings.data : undefined,
        loadingSettings: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Pending,
        loadingSettingsSuccess: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Success,
        loadingSettingsFailure: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Failure,
    
    };
};

const mapDispatchToProps = (dispatch): IPreRegistrationFormPageActions => {
    return {

        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadPreRegistrationForm: bindActionCreators(PreRegistrationFormActions.LoadPreRegistrationFormByPatientIdentifier, dispatch),
        savePreRegistrationForm: bindActionCreators(PreRegistrationFormActions.SavePreRegistrationForm, dispatch),
        createPreRegistrationForm: bindActionCreators(PreRegistrationFormActions.CreatePreRegistrationForm, dispatch),
        clearPreRegistrationForm: bindActionCreators(PreRegistrationFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),
        
        loadSettings: bindActionCreators(WebsiteSettingsActions.LoadWebsiteSettings, dispatch),

        toggleMessageModal: bindActionCreators(PreRegistrationFormPageActions.toggleMessageModal, dispatch),
        notifySetERStatus: bindActionCreators(PreRegistrationFormPageActions.notifySetERStatus, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PreRegistrationFormPage);
