import './SourceDocumentsFormPage.scss';

import * as React from 'react';

import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";

import { flow } from "lodash";

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as SourceDocumentsFormActions from '../../../actions/forms/sourceDocumentsForm';
import * as SourceDocumentsFormPageActions from '../../../actions/pages/registration/sourceDocumentsFormPage';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { ISourceDocumentsReduxFormState, initialSourceDocumentsFormState } from "../../../reducers/reactReduxForms/sourceDocumentsForm";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, ValidationMessage, NumberFormInput, CheckboxFormInput, GenericMultiFormGroup } from "../../../components/form/index";
import { mapProps, showErrors, validateOn } from "../../../helpers/reactReduxFormSettings";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import * as Moment from 'moment';
import { STANDARD_INTERNATIONAL_CODING_SYSTEM } from "../../../constants/inputMasks";
import { findFieldValidationFailures } from "../../../helpers/formErrorHelper";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';

import { NotificationAlert, MessageModal, Modal, ListGroupItem } from "../../../components/common/index";
import { RegistrationFormComponent, IRegistrationSourceDocuments, IRegistrationGrid, IRegistrationFormControl } from "../../../interfaces/forms/IRegistrationComponent";
import { renderFormControls, RegistrationComponent, getFormPropertyLabel } from "../../../helpers/formHelper";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { initialSourceDocumentState, ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import { GridRemove } from "../../../enumerations/GridRemove";
import { DEFAULT_FILE_UPLOAD_VIEW, SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { formatBytes, SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { setModalTitle } from "../../../helpers/modalHelper";
import { convertToShortDateString } from "../../../helpers/date";
import { requiredText, requiredSourceDocument } from "../../../validators/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { ResponseError } from '@servicestack/client';


interface ISourceDocumentsFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface ISourceDocumentsFormPageActions {

    loadSourceDocumentsForm: SourceDocumentsFormActions.ISourceDocumentsFormLoadByPatientIdentifierActionCreator,
    saveSourceDocumentsForm: SourceDocumentsFormActions.ISourceDocumentsFormSaveActionCreator,
    createSourceDocumentsForm: SourceDocumentsFormActions.ISourceDocumentsFormCreateActionCreator,
    clearSourceDocumentsForm: SourceDocumentsFormActions.ISourceDocumentsFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    //toggleMessageModal: SourceDocumentsFormPageActions.ISourceDocumentsFormPageToggleMessageModalActionCreator;
}

interface ISourceDocumentsFormPageProps {

    form: Dtos.SourceDocumentsForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: ISourceDocumentsReduxFormState;

    location: Location;
    match: match<ISourceDocumentsFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    sourceDocumentsForm: Dtos.SourceDocumentsForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingSourceDocumentsForm: boolean;
    loadSourceDocumentsFormSuccess: boolean;
    loadSourceDocumentsFormFailure: boolean;

    savingSourceDocumentsForm: boolean;
    saveSourceDocumentsFormSuccess: boolean;
    saveSourceDocumentsFormFailure: boolean;

    creatingSourceDocumentsForm: boolean;
    createSourceDocumentsFormSuccess: boolean;
    createSourceDocumentsFormFailure: boolean;

    //messageModalOpen: boolean;


}

type SourceDocumentsFormPageProps = ISourceDocumentsFormPageProps & ISourceDocumentsFormPageActions;

const reduxFormName: string = "reduxForms.sourceDocumentsForm"

class SourceDocumentsFormPage extends React.PureComponent<SourceDocumentsFormPageProps, any> {

    constructor(props: SourceDocumentsFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearSourceDocumentsForm = this.clearSourceDocumentsForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);

    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadSourceDocumentsForm,
            loadSourceDocumentsFormFailure,
            createSourceDocumentsForm
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadSourceDocumentsForm(identifier);



        }
        else if (!identifier) {
            createSourceDocumentsForm();
        }


        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: SourceDocumentsFormPageProps) {
    }

    componentDidUpdate(prevProps: SourceDocumentsFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingSourceDocumentsForm,
            loadSourceDocumentsFormSuccess,
            loadSourceDocumentsFormFailure,
            savingSourceDocumentsForm,
            saveSourceDocumentsFormSuccess,
            saveSourceDocumentsFormFailure,
            creatingSourceDocumentsForm,
            createSourceDocumentsFormSuccess,
            createSourceDocumentsFormFailure,
            createSourceDocumentsForm,
            sourceDocumentsForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            //toggleMessageModal,

        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/source-documents");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createSourceDocumentsForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }


        if (!loadingSourceDocumentsForm && prevProps.loadingSourceDocumentsForm) {
            if (loadSourceDocumentsFormSuccess) {
                loadForm(reduxFormName, { ...sourceDocumentsForm });
            }
            else {

            }
        }

        if (!savingSourceDocumentsForm && prevProps.savingSourceDocumentsForm) {
            if (saveSourceDocumentsFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    //toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    loadForm(reduxFormName, { ...sourceDocumentsForm });
                }

                if (!identifier) {

                    loadPatientById(sourceDocumentsForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingSourceDocumentsForm && prevProps.creatingSourceDocumentsForm) {
            if (createSourceDocumentsFormSuccess) {
                loadForm(reduxFormName, { ...sourceDocumentsForm });
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: SourceDocumentsFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearSourceDocumentsForm();
        loadForm(reduxFormName, initialSourceDocumentsFormState);
        resetForm(reduxFormName);
    }

    render() {

        const {
            loadingPatient,
            savingSourceDocumentsForm,
            loadingInstitution,
            loadingSourceDocumentsForm,
            loadSourceDocumentsFormSuccess,
            createSourceDocumentsFormSuccess,
            creatingSourceDocumentsForm

        } = this.props



        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingSourceDocumentsForm || creatingSourceDocumentsForm}
            saving={savingSourceDocumentsForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadSourceDocumentsFormSuccess,
            createSourceDocumentsFormSuccess,
            saveSourceDocumentsFormSuccess,
            reduxFormState,
            formState,
            //messageModalOpen,
            //toggleMessageModal,
            savingSourceDocumentsForm,
            formProperties,
            changeForm
        } = this.props;



        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingSourceDocumentsForm;
        const sourceDocumentsExist: boolean = form.sourceDocuments && form.sourceDocuments.length > 0;

        const formComponents: { [index: string]: RegistrationFormComponent } = {
            sourceDocumentsSummary: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="alert alert-info">
                    <div className="border-0 pt-4">
                        Please attach the following source documents:
                        <ul>
                            {

                                formProperties ?
                                    formProperties.map((formProperty, index) => {

                                        let propertyFailures: ResponseError[] = findFieldValidationFailures(formProperty.propertyName, validationFailures);
                                        let hasPropertyFailure: boolean = propertyFailures && propertyFailures.length > 0
                                        let sourceDocumentNotRequired: boolean =
                                            !this.sourceDocumentTypeExists(formProperty.propertyName) && !hasPropertyFailure;

                                        if (sourceDocumentNotRequired) {
                                            return null;
                                        }
                                        else {
                                            return <div id={"source-documents-form-property-" + index}>
                                                <div className="d-inline-block">
                                                    {
                                                        this.sourceDocumentTypeExists(formProperty.propertyName) ?
                                                            <div className="pr-4 text-success">
                                                                <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} fixedWidth />
                                                            </div> :
                                                            <div className="pr-4 text-danger">
                                                                <FontAwesomeIcon icon={FontAwesomeIcons.Regular.TIMES} fixedWidth />
                                                            </div>
                                                    }
                                                </div>
                                                <li className={classNames("d-inline-block")}>
                                                    {
                                                        formProperty.label
                                                    }
                                                </li>
                                            </div>
                                        }
                                    }) :
                                    null
                            }
                        </ul>
                        <p className="other-files">
                            Please ensure that you upload <b>all source documentation including <u>all</u> worksheets</b> i.e Eligibility sign off, tumour measurement etc. If your source document does not match one of the pre-determined file types, please upload the document as an 'Other' file type.
                        </p>
                        <p>
                            <strong>Instructions:</strong> Click the "Add" button to add a source document then click "Browse..." to upload the source document and "Submit Form" to save changes to the system.
                        </p>
                    </div>
                </div>
            },
            sourceDocuments: {
                type: RegistrationComponent.RegistrationSourceDocuments,
                label: " ",
                disabled: formDisabled,
                components: {
                    sourceDocuments: {
                        type: RegistrationComponent.RegistrationGrid,
                        formName: "SourceDocument",
                        disabled: formDisabled,
                        label: "Add Source Document",
                        className: "source-documents-source-documents",
                        minRows: 0,
                        columnStyles: [
                            {
                                width: undefined,
                                minWidth: "200px"
                            },
                            {
                                width: "23%",
                                minWidth: "150px"
                            },
                            {
                                width: "20%",
                                minWidth: "150px"
                            },
                            {
                                width: "125px"
                            },
                        ],
                        removeConfig: GridRemove.All,
                        addRow: (gridComponent, index, array) => {
                            const state: Dtos.SourceDocument = Object.assign(
                                { ...gridComponent.initialState }
                            );
                            return state;
                        },
                        components: {
                            name: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("SourceDocumentsForm", "FileName", formProperties),
                                inputProps: {
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: requiredText
                                },
                                errors: {
                                    required: "File name is required."
                                },
                                errorComponent: ValidationMessage

                            },
                            fileType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputLabel: getFormPropertyLabel("SourceDocumentsForm", "FileType", formProperties),
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups)),
                                    feedbackIcon: FontAwesomeIcons.Solid.BAN
                                },
                                validators: {
                                    required: value => value != undefined
                                },
                                errors: {
                                    required: "File type is required."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileInformation: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: FileUploadFormInput,
                                inputDisabled: formDisabled,
                                inputLabel: getFormPropertyLabel("SourceDocumentsForm", "Upload", formProperties),
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    const inputValue: Dtos.TemporaryFileInformation = value;
                                    const sourceDocument: Dtos.SourceDocument = form;

                                    if (sourceDocument.fileInformation &&
                                        inputValue &&
                                        sourceDocument.fileInformation.state != inputValue.state &&
                                        inputValue.state == Dtos.FileState.Success &&
                                        !sourceDocument.name) {
                                        changeForm(reduxFormName + parentModel + ".name", inputValue.temporaryFile.name.substr(0, inputValue.temporaryFile.name.lastIndexOf(inputValue.temporaryFile.extension)));
                                    }
                                },
                                inputProps: {
                                    render: SOURCE_DOCUMENT_UPLOAD_VIEW
                                },
                                validators: {
                                    required: requiredSourceDocument
                                },
                                errors: {
                                    required: "File must be uploaded."
                                },
                                errorComponent: ValidationMessage
                            },
                            fileConfiguration: {
                                type: RegistrationComponent.RegistrationGridElement,
                                label: " ",
                                render: SOURCE_DOCUMENT_FILE_CONFIGURATION
                            }
                        },
                        initialState: initialSourceDocumentState
                    },
                }
            }
        }


        return <div>
            <h1>Source Documents</h1>
            <Form model={reduxFormName} onSubmit={(val, event) => this.handleOnSubmit(val)} onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}>
                {
                    renderFormControls(form, "sourceDocumentsForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4">
                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2">Cancel</button>
                </div>
            </Form>
        </div>
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearSourceDocumentsForm() {
        this.props.clearSourceDocumentsForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    sourceDocumentTypeExists(sourceDocumentFieldName: string): boolean {

        const {
            form
        } = this.props

        let currentSourceDocumentType: Dtos.FileType =
            Dtos.FileType[sourceDocumentFieldName];

        return form.sourceDocuments.some(sd => {
            return sd.fileType !== undefined && sd.fileType == currentSourceDocumentType
        });
    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Source Documents"
            }
        ]
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        return {
            //consentGroupDisabled: form.hasWrittenConsent != 1,
            //subStudyConsentDateDisabled: form.mriSubStudyConsentNa,
            //ecogGroupDisabled: form.ecog02 != 1,
            //biopsyGroupDisabled: form.biopsyConfirmedRccKidney != 1,
            //dateRccLeftDisabled: form.diagnosisRenalCellCarcinomaLeftNa,
            //dateRccRightDisabled: form.diagnosisRenalCellCarcinomaRightNa,
            //GfrGroupDisabled: form.preTreatmentEstimatedGFR != 2,
        }

    }


    handleOnSubmit(data: Dtos.SourceDocumentsForm) {

        const {
            saveSourceDocumentsForm,
            institutionCode,
            identifier,
            validationFailures,
            changeForm
        } = this.props;

        if (data && institutionCode) {
            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.SourceDocumentsForm = {} as Dtos.SourceDocumentsForm;

            // consent group (all)
            //if (isDisabled.consentGroupDisabled) {
            //    changes.dateWrittenConsent = null;
            //    changes.consentMedicareDetails = null;
            //    changes.mriSubStudyConsentNa = null;
            //    changes.dateMriSubStudyConsent = null;
            //}

            //// NA checked for MRI sub study
            //if (isDisabled.subStudyConsentDateDisabled) {
            //    changes.dateMriSubStudyConsent = null;
            //}

            //// ECOG group disabled (all)
            //if (isDisabled.ecogGroupDisabled) {
            //    changes.dateClinicalAssessmentEcog = null;
            //    changes.ecogScore = null;
            //}

            //// Biopsy group disabled (all)
            //if (isDisabled.biopsyGroupDisabled) {
            //    changes.dateDiagnosisRenalCellCarcinomaLeft = null;
            //    changes.dateDiagnosisRenalCellCarcinomaRight = null;
            //    changes.diagnosisRenalCellCarcinomaLeftNa = null;
            //    changes.diagnosisRenalCellCarcinomaRightNa = null;
            //}

            //// Renal Cell Carcinoma (left) checkbox is checked
            //if (isDisabled.dateRccLeftDisabled) {
            //    changes.dateDiagnosisRenalCellCarcinomaLeft = null;
            //}

            //// Renal Cell Carcinoma (right) checkbox is checked
            //if (isDisabled.dateRccRightDisabled) {
            //    changes.dateDiagnosisRenalCellCarcinomaRight = null;
            //}

            //// GFR Group disabled (all)
            //if (isDisabled.GfrGroupDisabled) {
            //    changes.dateNuclearMedicineCalculatedGFR = null;
            //    changes.gfr = null;
            //    changes.dateBloodTest = null;
            //    changes.estimatedGFR = null;
            //    changes.calculateGRF = null;
            //}

            saveSourceDocumentsForm(Object.assign({ ...data }, changes), institutionCode, identifier);
        }

    }

    handleOnSubmitFailed(data: Dtos.SourceDocumentsForm) {

        const {
            institutionCode,
            identifier,
            validationFailures
        } = this.props;

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            //toggleMessageModal
        } = this.props

        //toggleMessageModal;
    }
}

const mapStateToProps = (state: IFigState, ownProps: SourceDocumentsFormPageProps): ISourceDocumentsFormPageProps => {

    const sourceDocumentsForm = !(state.registrationForms.sourceDocumentsForms.formData instanceof Array) ? state.registrationForms.sourceDocumentsForms.formData : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        sourceDocumentsForm: sourceDocumentsForm,
        lookups: state.registrationForms.sourceDocumentsForms.lookups,
        loadingSourceDocumentsForm: state.registrationForms.sourceDocumentsForms.loadState &&
            (state.registrationForms.sourceDocumentsForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.sourceDocumentsForms.loadState.status == RequestState.None &&
                    state.registrationForms.sourceDocumentsForms.createState.status == RequestState.None)),
        loadSourceDocumentsFormSuccess: state.registrationForms.sourceDocumentsForms.loadState && state.registrationForms.sourceDocumentsForms.loadState.status == RequestState.Success,
        loadSourceDocumentsFormFailure: state.registrationForms.sourceDocumentsForms.loadState && state.registrationForms.sourceDocumentsForms.loadState.status == RequestState.Failure,

        savingSourceDocumentsForm: state.registrationForms.sourceDocumentsForms.saveState && state.registrationForms.sourceDocumentsForms.saveState.status == RequestState.Pending,
        saveSourceDocumentsFormSuccess: state.registrationForms.sourceDocumentsForms.saveState && state.registrationForms.sourceDocumentsForms.saveState.status == RequestState.Success,
        saveSourceDocumentsFormFailure: state.registrationForms.sourceDocumentsForms.saveState && state.registrationForms.sourceDocumentsForms.saveState.status == RequestState.Failure,

        creatingSourceDocumentsForm: state.registrationForms.sourceDocumentsForms.createState && state.registrationForms.sourceDocumentsForms.createState.status == RequestState.Pending,
        createSourceDocumentsFormSuccess: state.registrationForms.sourceDocumentsForms.createState && state.registrationForms.sourceDocumentsForms.createState.status == RequestState.Success,
        createSourceDocumentsFormFailure: state.registrationForms.sourceDocumentsForms.createState && state.registrationForms.sourceDocumentsForms.createState.status == RequestState.Failure,

        form: state.reduxForms.sourceDocumentsForm,
        formState: state.registrationForms.sourceDocumentsForms.formState,
        formProperties: state.registrationForms.sourceDocumentsForms.formProperties,

        reduxFormState: state.reduxForms.formState.sourceDocumentsForm,
        validationFailures: sourceDocumentsForm ?
            (state.registrationForms.sourceDocumentsForms.saveState.status == RequestState.Success || state.registrationForms.sourceDocumentsForms.saveState.status == RequestState.Failure) ?
                state.registrationForms.sourceDocumentsForms.saveState &&
                    state.registrationForms.sourceDocumentsForms.saveState.errors ?
                    state.registrationForms.sourceDocumentsForms.saveState.errors :
                    undefined :
                state.registrationForms.sourceDocumentsForms.loadState &&
                    state.registrationForms.sourceDocumentsForms.loadState.errors ?
                    state.registrationForms.sourceDocumentsForms.loadState.errors :
                    undefined :
            undefined

        //messageModalOpen: state.sourceDocumentsFormPage.messageModalOpen
    };
};

const mapDispatchToProps = (dispatch): ISourceDocumentsFormPageActions => {
    return {
        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadSourceDocumentsForm: bindActionCreators(SourceDocumentsFormActions.LoadSourceDocumentsFormByPatientIdentifier, dispatch),
        saveSourceDocumentsForm: bindActionCreators(SourceDocumentsFormActions.SaveSourceDocumentsForm, dispatch),
        createSourceDocumentsForm: bindActionCreators(SourceDocumentsFormActions.CreateSourceDocumentsForm, dispatch),
        clearSourceDocumentsForm: bindActionCreators(SourceDocumentsFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        //toggleMessageModal: bindActionCreators(SourceDocumentsFormPageActions.toggleMessageModal, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SourceDocumentsFormPage);
