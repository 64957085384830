import * as React from "react";
import classNames from "classnames";
import ReactSelectClass from "react-select";
import { IBaseFormInputProps, TextFormInput } from '../';

import './DatePickerFormInputCustom.scss';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import * as Moment from 'moment';
import { DEFAULT_DATE_FORMAT, PARTIAL_DATE_FORMAT } from "../../../../constants/dateFormats";
import { isUtcDateString, isDateString, isShortDateFR } from "../../../../helpers/date";
import { OutsideClick } from "../../../common/index";
import { FieldAction, ChangeOptions, ModelAction } from "react-redux-form/react-redux-form";
import { mapProps } from "../../../../helpers/reactReduxFormSettings";


export interface IDatePickerFormInputCustomProps {
    displayFormat?: string;
    numberOfMonths?: number;
    isOutsideRange?: boolean;
    hideShortcuts?: boolean;
    addOnLeft: any;
}

export interface IDatePickerFormInputCustomState {
    resetting: boolean;
}

const dateFormat: string = "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[.]SSSSSSS[Z]";

class DatePickerFormInputCustom extends React.Component<IDatePickerFormInputCustomProps & IBaseFormInputProps, IDatePickerFormInputCustomState> {

    // @ts-ignore
    private container: HTMLDivElement;

    constructor(props) {
        super(props)

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);

        this.state = { resetting: false };

    }

    componentDidUpdate(prevProps: IDatePickerFormInputCustomProps & IBaseFormInputProps) {

        const {
            value,
            onChange,
            focused,
            name
        } = this.props

        const {
            resetting
        } = this.state;

        //if (this.container && !focused) {
        //    let inputNodes = this.container.getElementsByTagName("input");
        //    if (inputNodes && inputNodes.length > 0) {
        //        Array.from(inputNodes).forEach(node => {
        //            if (node.className.indexOf("DateInput_input") >= 0) {

        //                let inputValue: string = node.value
        //                if (inputValue && !value) {

        //                    onChange(Moment().format(dateFormat));
        //                    this.setState({ resetting: true });
        //                }
        //            }

        //        });
        //    }
        //}

        //if (resetting) {
        //    onChange(null);
        //    this.setState({ resetting: false });
        //}
    }

    render() {

        const {
            valid,
            invalid,
            className,
            name,
            onBlur,
            onFocus,
            value,
            disabled,
            focused,
            numberOfMonths,
            isOutsideRange,
            hideShortcuts,
            id,
            label,
            onChange,
            onKeyPress,
            addOnLeft,
            displayFormat
        } = this.props

        const derivedDisplayFormat = displayFormat ? displayFormat : DEFAULT_DATE_FORMAT;
        const derivedClassName = className ? className : undefined;
        const derivedHideShortcuts = hideShortcuts != undefined ? hideShortcuts : true;
        const derivedNumberOfMonths = numberOfMonths ? numberOfMonths : 1

        const date = value ?
            isUtcDateString(value) ?
                Moment.default(value) :
                isShortDateFR(value) ?
                    Moment.default(value, DEFAULT_DATE_FORMAT) :
                    undefined :
            undefined;

        const displayValue = date ?
            date.format(DEFAULT_DATE_FORMAT) :
            value;

        return (
            <div className={"SingleDatePicker SingleDatePicker_1"}>
                <OutsideClick onOutsideClick={() => { if (focused && onBlur) { onBlur() } }}>
                    <TextFormInput
                        id={id + "_input_text"}
                        className={classNames("form-control", { "focused": focused }, { "is-invalid": invalid })}
                        name={name}
                        focused={focused}
                        onChange={this.onDateChangeInput}
                        onFocus={onFocus}
                        maxLength={10}
                        label={label}
                        disabled={disabled}
                        value={displayValue}
                        valid={valid}
                        invalid={invalid}
                        onKeyPress={onKeyPress}
                        onKeyDown={(event: React.KeyboardEvent<any>) => {
                            if (event.keyCode == 9 && onBlur) {
                                onBlur();
                            }
                        }}
                        addOnLeft={addOnLeft}
                        mapProps={mapProps}
                    />
                    {
                        focused && !disabled ?
                            <div>
                                <svg className="DateInput_fang DateInput_fang_1" style={{ top: 59 }}>
                                    <path className="DateInput_fangShape DateInput_fangShape_1" d='M0,10 20,10 10,0z'
                                    />
                                    <path className="DateInput_fangStroke DateInput_fangStroke_1" d='M0,10 10,0 20,10'
                                    />
                                </svg>
                                <div onClick={onFocus}
                                    className={classNames("SingleDatePicker_picker SingleDatePicker_picker_1 SingleDatePicker_picker__directionLeft SingleDatePicker_picker__directionLeft_2")}
                                    style={{ top: "70px", left: "0px" }}>
                                    <DayPickerSingleDateController
                                        date={date}
                                        onDateChange={this.onDateChange} // PropTypes.func.isRequired
                                        focused={focused} // PropTypes.bool
                                        onFocusChange={this.onFocusChange}
                                        numberOfMonths={derivedNumberOfMonths}
                                        isOutsideRange={isOutsideRange}
                                        hideKeyboardShortcutsPanel={derivedHideShortcuts}
                                    />
                                </div>
                            </div> :
                            null
                    }
                </OutsideClick>
            </div>
        );
    }

    onDateChangeInput(value: any, options?: ChangeOptions): ModelAction {
        // @ts-ignore
        return undefined;
    }

    onDateChange(val: Moment.Moment) {
        if (!val) {
            console.log(val);
            this.props.onChange(null);
        }
        else {
            console.log(val);
            this.props.onChange(val.format(dateFormat));
        }
    }

    onFocusChange(event: { focused: boolean }) {

        const {
            onFocus,
            onBlur

        } = this.props

        if (event.focused && onFocus) {
            onFocus();
        }
        else if (onBlur) {
            onBlur();
        }
    }
}

export default DatePickerFormInputCustom;