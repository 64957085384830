import * as React from 'react';
import './EligibilityCriteriaFormPage.scss';


import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";

import { flow } from "lodash";

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as EligibilityCriteriaFormActions from '../../../actions/forms/eligibilityCriteriaForm';
import * as EligibilityCriteriaFormPageActions from '../../../actions/pages/registration/eligibilityCriteriaFormPage';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { IEligibilityCriteriaReduxFormState, initialEligibilityCriteriaFormState } from "../../../reducers/reactReduxForms/eligibilityCriteriaForm";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { mapProps, showErrors, validateOn } from "../../../helpers/reactReduxFormSettings";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import * as Moment from 'moment';
import { STANDARD_INTERNATIONAL_CODING_SYSTEM, LANGUAGE_SPOKEN } from "../../../constants/inputMasks";
import { findFieldValidationFailures } from "../../../helpers/formErrorHelper";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import Modal from 'react-responsive-modal';
import { NotificationAlert, MessageModal } from "../../../components/common/index";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { GridRemove } from "../../../enumerations/GridRemove";
import { requiredText, requiredSourceDocument, validateByRegex, validationRegex, softErrorMessages, validateDateRange, ComparisonTimeType, DateRangeValidateType } from "../../../validators/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { initialSourceDocumentState, ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import { DatePickerFormInputFabric, TextAreaFormInput } from '../../../components/form/inputs';
import { FileState } from '../../../dtos/Fig.dtos';



interface IEligibilityCriteriaFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface IEligibilityCriteriaFormPageActions {

    loadEligibilityCriteriaForm: EligibilityCriteriaFormActions.IEligibilityCriteriaFormLoadByPatientIdentifierActionCreator,
    saveEligibilityCriteriaForm: EligibilityCriteriaFormActions.IEligibilityCriteriaFormSaveActionCreator,
    createEligibilityCriteriaForm: EligibilityCriteriaFormActions.IEligibilityCriteriaFormCreateActionCreator,
    clearEligibilityCriteriaForm: EligibilityCriteriaFormActions.IEligibilityCriteriaFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleMessageModal: EligibilityCriteriaFormPageActions.IEligibilityCriteriaFormPageToggleMessageModalActionCreator;
}

interface IEligibilityCriteriaFormPageProps {

    form: Dtos.EligibilityCriteriaForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: IEligibilityCriteriaReduxFormState;

    location: Location;
    match: match<IEligibilityCriteriaFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    eligibilityCriteriaForm: Dtos.EligibilityCriteriaForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingEligibilityCriteriaForm: boolean;
    loadEligibilityCriteriaFormSuccess: boolean;
    loadEligibilityCriteriaFormFailure: boolean;

    savingEligibilityCriteriaForm: boolean;
    saveEligibilityCriteriaFormSuccess: boolean;
    saveEligibilityCriteriaFormFailure: boolean;

    creatingEligibilityCriteriaForm: boolean;
    createEligibilityCriteriaFormSuccess: boolean;
    createEligibilityCriteriaFormFailure: boolean;

    messageModalOpen: boolean;
}

type EligibilityCriteriaFormPageProps = IEligibilityCriteriaFormPageProps & IEligibilityCriteriaFormPageActions;

const formName: string = "EligibilityCriteriaForm";
const reduxFormName: string = "reduxForms.eligibilityCriteriaForm";
const groupClassNames: string = 'form-group row border border-bottom-1 border-top-0 pb-4 pt-4';

class EligibilityCriteriaFormPage extends React.PureComponent<EligibilityCriteriaFormPageProps, any> {

    constructor(props: EligibilityCriteriaFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearEligibilityCriteriaForm = this.clearEligibilityCriteriaForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadEligibilityCriteriaForm,
            createEligibilityCriteriaForm
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadEligibilityCriteriaForm(identifier);
        }
        else if (!identifier) {
            createEligibilityCriteriaForm();
        }
        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: EligibilityCriteriaFormPageProps) {
    }

    componentDidUpdate(prevProps: EligibilityCriteriaFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingEligibilityCriteriaForm,
            loadEligibilityCriteriaFormSuccess,
            loadEligibilityCriteriaFormFailure,
            savingEligibilityCriteriaForm,
            saveEligibilityCriteriaFormSuccess,
            saveEligibilityCriteriaFormFailure,
            creatingEligibilityCriteriaForm,
            createEligibilityCriteriaFormSuccess,
            createEligibilityCriteriaFormFailure,
            createEligibilityCriteriaForm,
            eligibilityCriteriaForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            toggleMessageModal,

        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/pre-registration");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createEligibilityCriteriaForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }


        if (!loadingEligibilityCriteriaForm && prevProps.loadingEligibilityCriteriaForm) {
            if (loadEligibilityCriteriaFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingEligibilityCriteriaForm && prevProps.savingEligibilityCriteriaForm) {
            if (saveEligibilityCriteriaFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    this.loadForm();
                }

                if (!identifier) {

                    loadPatientById(eligibilityCriteriaForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingEligibilityCriteriaForm && prevProps.creatingEligibilityCriteriaForm) {
            if (createEligibilityCriteriaFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: EligibilityCriteriaFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearEligibilityCriteriaForm();
        loadForm(reduxFormName, initialEligibilityCriteriaFormState);
        resetForm(reduxFormName);
    }

    render() {

        const {
            loadingPatient,
            savingEligibilityCriteriaForm,
            loadingInstitution,
            loadingEligibilityCriteriaForm,
            loadEligibilityCriteriaFormSuccess,
            createEligibilityCriteriaFormSuccess,
            creatingEligibilityCriteriaForm
        } = this.props



        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingEligibilityCriteriaForm || creatingEligibilityCriteriaForm}
            saving={savingEligibilityCriteriaForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadEligibilityCriteriaFormSuccess,
            createEligibilityCriteriaFormSuccess,
            saveEligibilityCriteriaFormSuccess,
            reduxFormState,
            formState,
            messageModalOpen,
            toggleMessageModal,
            savingEligibilityCriteriaForm,
            formProperties,
            changeForm
        } = this.props;


        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingEligibilityCriteriaForm;
        
        let group1: boolean = (patient && patient.registrationGroup != undefined && patient.registrationGroup == 0);
        let group2: boolean = (patient && patient.registrationGroup != undefined && patient.registrationGroup == 1);
        
        const {
            priorTreatmentsDisabled,
        } = this.handleDisabledValues();

        const formComponents: { [index: string]: RegistrationFormComponent } = {
            confirmedBy: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            confirmedDate: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,               
                inputProps: {
                    maxDate: new Date()
                },
            },  
            inclusionCriteriaHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h2>Inclusion Criteria</h2>
                </div>
            },
            ageGreaterEighteen: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "AgeGreaterEighteen", lookups))
                }
            },
            histologicalGBMOrIDH: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "histologicalGBMOrIDH", lookups))
                }
            },
            mgmt: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "mgmt", lookups))
                }
            },
            ecog: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "ecog", lookups))
                }
            },
            lifeExpectancyGreater12Weeks: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "lifeExpectancyGreater12Weeks", lookups))
                }
            },
            adequateBoneMarrowReserver: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "adequateBoneMarrowReserver", lookups))
                }
            },
            capableOfChildbearing: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "capableOfChildbearing", lookups))
                }
            },
            complyStudyRequirements: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "complyStudyRequirements", lookups))
                }
            },
            writtenConsent: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "writtenConsent", lookups))
                }
            },
            radiotherapySuitable: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || !group1,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "radiotherapySuitable", lookups))
                },
                groupClassName: !group1 ? "hide-component" : groupClassNames
            },
            undergoingRadiotherapy: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || !group2,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "undergoingRadiotherapy", lookups))
                },
                groupClassName: !group2 ? "hide-component" : groupClassNames
            },
            commencedChemoradiationLessThanSevenWeeksBeforeSurgery: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || !group2,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "commencedChemoradiationLessThanSevenWeeksBeforeSurgery", lookups))
                },
                groupClassName: !group2 ? "hide-component" : groupClassNames
            },
            suitableForAdjuvantTMZTherapy: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || !group2,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "suitableForAdjuvantTMZTherapy", lookups))
                },
                groupClassName: !group2 ? "hide-component" : groupClassNames
            },
            exclusionCriteriaHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h2>Exclusion Criteria</h2>
                </div>
            },
            implantedMedicalElectronicDevice: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "ImplantedMedicalElectronicDevice", lookups))
                }
            },
            concurrentConditions: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "concurrentConditions", lookups))
                }
            },
            historyOfAnotherMalignancyWithin2Years: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "historyOfAnotherMalignancyWithin2Years", lookups))
                }
            },
            priorChemotherapyWithin2Years: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || !group1,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("EligibilityCriteriaForm", "priorChemotherapyWithin2Years", lookups))
                },
                groupClassName: !group1 ? "hide-component" : groupClassNames
            }
        };


        return <div>
            <h1>Eligibility Confirmation Form</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "eligibilityCriteriaForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearEligibilityCriteriaForm() {
        this.props.clearEligibilityCriteriaForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Eligibility Confirmation Form"
            }
        ]
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        return {
            priorTreatmentsDisabled: false,
        }
    }


    handleOnSubmit(data: Dtos.EligibilityCriteriaForm) {

        const {
            saveEligibilityCriteriaForm,
            institutionCode,
            identifier,
            validationFailures,
            changeForm

        } = this.props;

        if (data && institutionCode) {

            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.EligibilityCriteriaForm = {} as Dtos.EligibilityCriteriaForm;

            saveEligibilityCriteriaForm(Object.assign({ ...data }, changes), institutionCode, identifier);
        }

    }


    handleOnSubmitFailed(data: Dtos.EligibilityCriteriaForm) {

        const { institutionCode,
            identifier,
        } = this.props;

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props
        // eslint-disable-next-line
        toggleMessageModal;
    }

    loadForm() {
        const {
            loadForm,
            eligibilityCriteriaForm
        } = this.props

        let extraData: Dtos.EligibilityCriteriaForm = {} as Dtos.EligibilityCriteriaForm;

        loadForm(reduxFormName, Object.assign({ ...eligibilityCriteriaForm }, extraData));
    }
}

const mapStateToProps = (state: IFigState, ownProps: EligibilityCriteriaFormPageProps): IEligibilityCriteriaFormPageProps => {

    const eligibilityCriteriaForm = !(state.registrationForms.eligibilityCriteriaForms.formData instanceof Array) ? state.registrationForms.eligibilityCriteriaForms.formData : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        eligibilityCriteriaForm: eligibilityCriteriaForm,
        lookups: state.registrationForms.eligibilityCriteriaForms.lookups,
        loadingEligibilityCriteriaForm: state.registrationForms.eligibilityCriteriaForms.loadState &&
            (state.registrationForms.eligibilityCriteriaForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.eligibilityCriteriaForms.loadState.status == RequestState.None &&
                    state.registrationForms.eligibilityCriteriaForms.createState.status == RequestState.None)),
        loadEligibilityCriteriaFormSuccess: state.registrationForms.eligibilityCriteriaForms.loadState && state.registrationForms.eligibilityCriteriaForms.loadState.status == RequestState.Success,
        loadEligibilityCriteriaFormFailure: state.registrationForms.eligibilityCriteriaForms.loadState && state.registrationForms.eligibilityCriteriaForms.loadState.status == RequestState.Failure,

        savingEligibilityCriteriaForm: state.registrationForms.eligibilityCriteriaForms.saveState && state.registrationForms.eligibilityCriteriaForms.saveState.status == RequestState.Pending,
        saveEligibilityCriteriaFormSuccess: state.registrationForms.eligibilityCriteriaForms.saveState && state.registrationForms.eligibilityCriteriaForms.saveState.status == RequestState.Success,
        saveEligibilityCriteriaFormFailure: state.registrationForms.eligibilityCriteriaForms.saveState && state.registrationForms.eligibilityCriteriaForms.saveState.status == RequestState.Failure,

        creatingEligibilityCriteriaForm: state.registrationForms.eligibilityCriteriaForms.createState && state.registrationForms.eligibilityCriteriaForms.createState.status == RequestState.Pending,
        createEligibilityCriteriaFormSuccess: state.registrationForms.eligibilityCriteriaForms.createState && state.registrationForms.eligibilityCriteriaForms.createState.status == RequestState.Success,
        createEligibilityCriteriaFormFailure: state.registrationForms.eligibilityCriteriaForms.createState && state.registrationForms.eligibilityCriteriaForms.createState.status == RequestState.Failure,

        form: state.reduxForms.eligibilityCriteriaForm,
        formState: state.registrationForms.eligibilityCriteriaForms.formState,
        formProperties: state.registrationForms.eligibilityCriteriaForms.formProperties,

        reduxFormState: state.reduxForms.formState.eligibilityCriteriaForm,
        validationFailures: eligibilityCriteriaForm && eligibilityCriteriaForm.id ?
            state.registrationForms.eligibilityCriteriaForms.validationFailures :
            undefined,

        messageModalOpen: state.eligibilityCriteriaFormPage.messageModalOpen
    };
};

const mapDispatchToProps = (dispatch): IEligibilityCriteriaFormPageActions => {
    return {
        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadEligibilityCriteriaForm: bindActionCreators(EligibilityCriteriaFormActions.LoadEligibilityCriteriaFormByPatientIdentifier, dispatch),
        saveEligibilityCriteriaForm: bindActionCreators(EligibilityCriteriaFormActions.SaveEligibilityCriteriaForm, dispatch),
        createEligibilityCriteriaForm: bindActionCreators(EligibilityCriteriaFormActions.CreateEligibilityCriteriaForm, dispatch),
        clearEligibilityCriteriaForm: bindActionCreators(EligibilityCriteriaFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleMessageModal: bindActionCreators(EligibilityCriteriaFormPageActions.toggleMessageModal, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(EligibilityCriteriaFormPage);
