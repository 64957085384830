import * as React from 'react';
import './PatientReportedFormPage.scss';


import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";

import { flow } from "lodash";

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as PatientReportedFormActions from '../../../actions/forms/patientReportedForm';
import * as PatientReportedFormPageActions from '../../../actions/pages/registration/patientReportedFormPage';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { IPatientReportedReduxFormState, initialPatientReportedFormState } from "../../../reducers/reactReduxForms/patientReportedForm";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { mapProps, showErrors, validateOn } from "../../../helpers/reactReduxFormSettings";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import * as Moment from 'moment';
import { STANDARD_INTERNATIONAL_CODING_SYSTEM, LANGUAGE_SPOKEN } from "../../../constants/inputMasks";
import { findFieldValidationFailures } from "../../../helpers/formErrorHelper";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import Modal from 'react-responsive-modal';
import { NotificationAlert, MessageModal } from "../../../components/common/index";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { GridRemove } from "../../../enumerations/GridRemove";
import { requiredText, requiredSourceDocument, validateByRegex, validationRegex, softErrorMessages, validateDateRange, ComparisonTimeType, DateRangeValidateType } from "../../../validators/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { initialSourceDocumentState, ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import { DatePickerFormInputFabric, TextAreaFormInput } from '../../../components/form/inputs';
import { FileState } from '../../../dtos/Fig.dtos';



interface IPatientReportedFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface IPatientReportedFormPageActions {

    loadPatientReportedForm: PatientReportedFormActions.IPatientReportedFormLoadByPatientIdentifierActionCreator,
    savePatientReportedForm: PatientReportedFormActions.IPatientReportedFormSaveActionCreator,
    createPatientReportedForm: PatientReportedFormActions.IPatientReportedFormCreateActionCreator,
    clearPatientReportedForm: PatientReportedFormActions.IPatientReportedFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleMessageModal: PatientReportedFormPageActions.IPatientReportedFormPageToggleMessageModalActionCreator;
}

interface IPatientReportedFormPageProps {

    form: Dtos.PatientReportedForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: IPatientReportedReduxFormState;

    location: Location;
    match: match<IPatientReportedFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    patientReportedForm: Dtos.PatientReportedForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingPatientReportedForm: boolean;
    loadPatientReportedFormSuccess: boolean;
    loadPatientReportedFormFailure: boolean;

    savingPatientReportedForm: boolean;
    savePatientReportedFormSuccess: boolean;
    savePatientReportedFormFailure: boolean;

    creatingPatientReportedForm: boolean;
    createPatientReportedFormSuccess: boolean;
    createPatientReportedFormFailure: boolean;

    messageModalOpen: boolean;
}

type PatientReportedFormPageProps = IPatientReportedFormPageProps & IPatientReportedFormPageActions;

const formName: string = "PatientReportedForm";
const reduxFormName: string = "reduxForms.patientReportedForm";

class PatientReportedFormPage extends React.PureComponent<PatientReportedFormPageProps, any> {

    constructor(props: PatientReportedFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearPatientReportedForm = this.clearPatientReportedForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadPatientReportedForm,
            createPatientReportedForm
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadPatientReportedForm(identifier);
        }
        else if (!identifier) {
            createPatientReportedForm();
        }
        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: PatientReportedFormPageProps) {
    }

    componentDidUpdate(prevProps: PatientReportedFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingPatientReportedForm,
            loadPatientReportedFormSuccess,
            loadPatientReportedFormFailure,
            savingPatientReportedForm,
            savePatientReportedFormSuccess,
            savePatientReportedFormFailure,
            creatingPatientReportedForm,
            createPatientReportedFormSuccess,
            createPatientReportedFormFailure,
            createPatientReportedForm,
            patientReportedForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            toggleMessageModal,

        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/pre-registration");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createPatientReportedForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }


        if (!loadingPatientReportedForm && prevProps.loadingPatientReportedForm) {
            if (loadPatientReportedFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingPatientReportedForm && prevProps.savingPatientReportedForm) {
            if (savePatientReportedFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    this.loadForm();
                }

                if (!identifier) {

                    loadPatientById(patientReportedForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingPatientReportedForm && prevProps.creatingPatientReportedForm) {
            if (createPatientReportedFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: PatientReportedFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearPatientReportedForm();
        loadForm(reduxFormName, initialPatientReportedFormState);
        resetForm(reduxFormName);
    }

    render() {

        const {
            loadingPatient,
            savingPatientReportedForm,
            loadingInstitution,
            loadingPatientReportedForm,
            loadPatientReportedFormSuccess,
            createPatientReportedFormSuccess,
            creatingPatientReportedForm
        } = this.props



        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingPatientReportedForm || creatingPatientReportedForm}
            saving={savingPatientReportedForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadPatientReportedFormSuccess,
            createPatientReportedFormSuccess,
            savePatientReportedFormSuccess,
            reduxFormState,
            formState,
            messageModalOpen,
            toggleMessageModal,
            savingPatientReportedForm,
            formProperties,
            changeForm
        } = this.props;


        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingPatientReportedForm;
        
        const {
            eortcqlqC30FormCompleted,
            requiredAssistanceCompletingQuestionnaire,
        } = this.handleDisabledValues();

        const formComponents: { [index: string]: RegistrationFormComponent } = {
            comidaData: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h2>COMIDA Data</h2>
                </div>
            },
            assessmentDate: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,               
                inputProps: {
                    maxDate: new Date()
                },
            },  
            eortcqlqC30FormCompleted: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "EortcqlqC30FormCompleted", lookups))
                },
                changeAction: (model, value, form, parentModel) => {
                    changeForm(model, value);
                    if (value != 0) {
                        changeForm(reduxFormName + parentModel + '.requiredAssistanceCompletingQuestionnaire', null);
                        changeForm(reduxFormName + parentModel + '.requiredAssistanceCompletingQuestionnaireSpecify', null);
                        changeForm(reduxFormName + parentModel + '.howWasQuestionnaireAdministered', null);
                        changeForm(reduxFormName + parentModel + '.howWasQuestionnaireAdministeredSpecify', null);
                        changeForm(reduxFormName + parentModel + '.languageUsed', null);
                        changeForm(reduxFormName + parentModel + '.languageUsedSpecify', null);
                    }
                    if (value != 1) {
                        changeForm(reduxFormName + parentModel + '.appropriateReasonNonCompletion', null);
                        changeForm(reduxFormName + parentModel + '.participantsLanguage', null);
                        changeForm(reduxFormName + parentModel + '.nonCompletionIllnessRelated', null);
                    }
                },
            },
            requiredAssistanceCompletingQuestionnaireGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "patientReportedForm",
                            {
                                requiredAssistanceCompletingQuestionnaire: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: SelectFormInput,
                                    inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4",
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("PatientReportedForm", "requiredAssistanceCompletingQuestionnaire", lookups))
                                    },
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value != 0) {
                                            changeForm(reduxFormName + parentModel + '.requiredAssistanceCompletingQuestionnaireSpecify', null);
                                        }
                                    },
                                },
                                requiredAssistanceCompletingQuestionnaireSpecify: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    inputDisabled: formDisabled || !requiredAssistanceCompletingQuestionnaire,
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                }
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            howWasQuestionnaireAdministeredGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "patientReportedForm",
                            {
                                howWasQuestionnaireAdministered: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: SelectFormInput,
                                    inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4",
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("PatientReportedForm", "howWasQuestionnaireAdministered", lookups))
                                    },
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value != 3) {
                                            changeForm(reduxFormName + parentModel + '.howWasQuestionnaireAdministeredSpecify', null);
                                        }
                                    },
                                },
                                howWasQuestionnaireAdministeredSpecify: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                                    disabledFn: (form: Dtos.PatientReportedForm, arrayKey: string) => {
                                        if (formDisabled || !eortcqlqC30FormCompleted) return true
                                        if (form) {
                                            if (form.howWasQuestionnaireAdministered == 3) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                }
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            languageUsedGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "patientReportedForm",
                            {
                                languageUsed: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: SelectFormInput,
                                    inputDisabled: formDisabled  || !eortcqlqC30FormCompleted,
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4",
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("PatientReportedForm", "languageUsed", lookups))
                                    },
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value != 6) {
                                            changeForm(reduxFormName + parentModel + '.languageUsedSpecify', null);
                                        }
                                    },
                                },
                                languageUsedSpecify: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    disabledFn: (form: Dtos.PatientReportedForm, arrayKey: string) => {
                                        if (formDisabled || !eortcqlqC30FormCompleted) return true
                                        if (form) {
                                            if (form.languageUsed == 6) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                }
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            appropriateReasonNonCompletionGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "patientReportedForm",
                            {
                                appropriateReasonNonCompletion: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: SelectFormInput,
                                    inputDisabled: formDisabled  || eortcqlqC30FormCompleted,
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4",
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("PatientReportedForm", "appropriateReasonNonCompletion", lookups))
                                    },
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value != 6) {
                                            changeForm(reduxFormName + parentModel + '.participantsLanguage', null);
                                        }
                                    },
                                },
                                participantsLanguage: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: TextFormInput,
                                    disabledFn: (form: Dtos.PatientReportedForm, arrayKey: string) => {
                                        if (formDisabled || eortcqlqC30FormCompleted) return true
                                        if (form) {
                                            if (form.appropriateReasonNonCompletion == 6) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    fieldClassName: "px-4 col-sm-3",
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                }
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            nonCompletionIllnessRelated: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled  || eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "nonCompletionIllnessRelated", lookups))
                }
            },
            notes: {
                type: RegistrationComponent.RegistrationControl,
                inputType: TextFormInput,
                inputDisabled: formDisabled,
            },
            eortcData: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h2>EORTC QLQ C30  Data</h2>
                </div>
            },
            troubleDoingStrenuousActivities: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "troubleDoingStrenuousActivities", lookups))
                }
            },
            troubleTakingALongWalk: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "troubleTakingALongWalk", lookups))
                }
            },
            troubleTakingShortWalkOutsideHouse: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "troubleTakingShortWalkOutsideHouse", lookups))
                }
            },
            needToStayInBedOrChairDuringDay: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "needToStayInBedOrChairDuringDay", lookups))
                }
            },
            helpEatingDressinWashingToilet: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "helpEatingDressinWashingToilet", lookups))
                }
            },
            limitedDoingWorkDailyActivities: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "limitedDoingWorkDailyActivities", lookups))
                }
            },
            limitedPursuingHobbies: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "limitedPursuingHobbies", lookups))
                }
            },
            shortOfBreath: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "shortOfBreath", lookups))
                }
            },
            pain: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "pain", lookups))
                }
            },
            needARest: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "needARest", lookups))
                }
            },
            troubleSleeping: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "troubleSleeping", lookups))
                }
            },
            feltWeak: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "feltWeak", lookups))
                }
            },
            lackedAppetite: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "lackedAppetite", lookups))
                }
            },
            feltNauseated: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "feltNauseated", lookups))
                }
            },
            vomited: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "vomited", lookups))
                }
            },
            consipated: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "consipated", lookups))
                }
            },
            diarrhoea: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "diarrhoea", lookups))
                }
            },
            tired: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "tired", lookups))
                }
            },
            painInterferedDailyActivities: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "painInterferedDailyActivities", lookups))
                }
            },
            difficultyConcentrating: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "difficultyConcentrating", lookups))
                }
            },
            tense: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "tense", lookups))
                }
            },
            worry: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "worry", lookups))
                }
            },
            irritable: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "irritable", lookups))
                }
            },
            depressed: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "depressed", lookups))
                }
            },
            difficultyRememberingThings: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "difficultyRememberingThings", lookups))
                }
            },
            interferedFamily: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "interferedFamily", lookups))
                }
            },
            interferedSocial: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "interferedSocial", lookups))
                }
            },
            financialDifficulties: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "financialDifficulties", lookups))
                }
            },
            overallHealth: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "overallHealth", lookups))
                }
            },
            overallQualityOfLife: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled || !eortcqlqC30FormCompleted,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("PatientReportedForm", "overallQualityOfLife", lookups))
                }
            }
        };


        return <div>
            <h1>Patient Reported Outcomes Form</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "patientReportedForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearPatientReportedForm() {
        this.props.clearPatientReportedForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Patient Reported Outcomes Form"
            }
        ]
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        let eortcqlqC30FormCompleted:boolean = false;
        if(form.eortcqlqC30FormCompleted != undefined) {
            eortcqlqC30FormCompleted = form.eortcqlqC30FormCompleted == 0;
        }

        let requiredAssistanceCompletingQuestionnaire:boolean = false;
        if (form.requiredAssistanceCompletingQuestionnaire != undefined) {
            requiredAssistanceCompletingQuestionnaire = form.requiredAssistanceCompletingQuestionnaire == 0;
        }

        return {
            eortcqlqC30FormCompleted,
            requiredAssistanceCompletingQuestionnaire
        }
    }


    handleOnSubmit(data: Dtos.PatientReportedForm) {

        const {
            savePatientReportedForm,
            institutionCode,
            identifier,
            validationFailures,
            changeForm

        } = this.props;

        if (data && institutionCode) {

            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.PatientReportedForm = {} as Dtos.PatientReportedForm;

            savePatientReportedForm(Object.assign({ ...data }, changes), institutionCode, identifier);
        }

    }


    handleOnSubmitFailed(data: Dtos.PatientReportedForm) {

        const { institutionCode,
            identifier,
        } = this.props;

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props
        // eslint-disable-next-line
        toggleMessageModal;
    }

    loadForm() {
        const {
            loadForm,
            patientReportedForm
        } = this.props

        let extraData: Dtos.PatientReportedForm = {} as Dtos.PatientReportedForm;

        loadForm(reduxFormName, Object.assign({ ...patientReportedForm }, extraData));
    }
}

const mapStateToProps = (state: IFigState, ownProps: PatientReportedFormPageProps): IPatientReportedFormPageProps => {

    const patientReportedForm = !(state.registrationForms.patientReportedForms.formData instanceof Array) ? state.registrationForms.patientReportedForms.formData : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        patientReportedForm: patientReportedForm,
        lookups: state.registrationForms.patientReportedForms.lookups,
        loadingPatientReportedForm: state.registrationForms.patientReportedForms.loadState &&
            (state.registrationForms.patientReportedForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.patientReportedForms.loadState.status == RequestState.None &&
                    state.registrationForms.patientReportedForms.createState.status == RequestState.None)),
        loadPatientReportedFormSuccess: state.registrationForms.patientReportedForms.loadState && state.registrationForms.patientReportedForms.loadState.status == RequestState.Success,
        loadPatientReportedFormFailure: state.registrationForms.patientReportedForms.loadState && state.registrationForms.patientReportedForms.loadState.status == RequestState.Failure,

        savingPatientReportedForm: state.registrationForms.patientReportedForms.saveState && state.registrationForms.patientReportedForms.saveState.status == RequestState.Pending,
        savePatientReportedFormSuccess: state.registrationForms.patientReportedForms.saveState && state.registrationForms.patientReportedForms.saveState.status == RequestState.Success,
        savePatientReportedFormFailure: state.registrationForms.patientReportedForms.saveState && state.registrationForms.patientReportedForms.saveState.status == RequestState.Failure,

        creatingPatientReportedForm: state.registrationForms.patientReportedForms.createState && state.registrationForms.patientReportedForms.createState.status == RequestState.Pending,
        createPatientReportedFormSuccess: state.registrationForms.patientReportedForms.createState && state.registrationForms.patientReportedForms.createState.status == RequestState.Success,
        createPatientReportedFormFailure: state.registrationForms.patientReportedForms.createState && state.registrationForms.patientReportedForms.createState.status == RequestState.Failure,

        form: state.reduxForms.patientReportedForm,
        formState: state.registrationForms.patientReportedForms.formState,
        formProperties: state.registrationForms.patientReportedForms.formProperties,

        reduxFormState: state.reduxForms.formState.patientReportedForm,
        validationFailures: patientReportedForm && patientReportedForm.id ?
            state.registrationForms.patientReportedForms.validationFailures :
            undefined,

        messageModalOpen: state.patientReportedFormPage.messageModalOpen
    };
};

const mapDispatchToProps = (dispatch): IPatientReportedFormPageActions => {
    return {
        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadPatientReportedForm: bindActionCreators(PatientReportedFormActions.LoadPatientReportedFormByPatientIdentifier, dispatch),
        savePatientReportedForm: bindActionCreators(PatientReportedFormActions.SavePatientReportedForm, dispatch),
        createPatientReportedForm: bindActionCreators(PatientReportedFormActions.CreatePatientReportedForm, dispatch),
        clearPatientReportedForm: bindActionCreators(PatientReportedFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleMessageModal: bindActionCreators(PatientReportedFormPageActions.toggleMessageModal, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PatientReportedFormPage);
