import * as React from 'react';
import './ClinicalAssessmentFormPage.scss';


import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Form, Control, actions, Errors } from "react-redux-form";

import { flow } from "lodash";

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as ClinicalAssessmentFormActions from '../../../actions/forms/clinicalAssessmentForm';
import * as ClinicalAssessmentFormPageActions from '../../../actions/pages/registration/clinicalAssessmentFormPage';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { IClinicalAssessmentReduxFormState, initialClinicalAssessmentFormState, initialClinicalAssessmentFormConcomitantState } from "../../../reducers/reactReduxForms/clinicalAssessmentForm";
import { TextFormInput, GenericFormGroup, RadioButtonSetFormInput, SelectFormInput, DatePickerFormInput, DatePickerPartialFormInput, MaskedFormInput, NumberFormInput, CheckboxFormInput, StaticFormInput, GenericMultiFormGroup, ValidationMessage } from "../../../components/form/index";
import { mapProps, showErrors, validateOn } from "../../../helpers/reactReduxFormSettings";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import * as Moment from 'moment';
import { STANDARD_INTERNATIONAL_CODING_SYSTEM, NUMBER_XXX } from "../../../constants/inputMasks";
import { findFieldValidationFailures } from "../../../helpers/formErrorHelper";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import Alert from 'react-s-alert';
import Modal from 'react-responsive-modal';
import { NotificationAlert, MessageModal } from "../../../components/common/index";
import { renderFormControls, RegistrationComponent, getFormProperty, getFormPropertyLabel } from "../../../helpers/formHelper";
import { IRegistrationElement, RegistrationFormComponent, IRegistrationGrid } from "../../../interfaces/forms/IRegistrationComponent";
import { GridRemove } from "../../../enumerations/GridRemove";
import { requiredText, requiredSourceDocument, validateByRegex, validationRegex, softErrorMessages, validateDateRange, ComparisonTimeType, DateRangeValidateType, errorMessages, maxPrecisionTwo, maxPrecisionOne } from "../../../validators/index";
import { FileUploadFormInput } from "../../../components/form/inputs/FileUploadFormInput/index";
import { renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning, renderRegistrationFormSaveError, renderRegistrationFormSaveBlock } from "../../../helpers/alert";
import { SOURCE_DOCUMENT_UPLOAD_VIEW } from "../../../components/form/inputs/FileUploadFormInput/FileUploadViews";
import { SOURCE_DOCUMENT_FILE_CONFIGURATION } from "../../../helpers/file";
import { initialSourceDocumentState, ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import { DatePickerFormInputFabric, TextAreaFormInput } from '../../../components/form/inputs';
import { FileState } from '../../../dtos/Fig.dtos';
import { detect } from 'detect-browser';


interface IClinicalAssessmentFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface IClinicalAssessmentFormPageActions {

    loadClinicalAssessmentForm: ClinicalAssessmentFormActions.IClinicalAssessmentFormLoadByPatientIdentifierActionCreator,
    saveClinicalAssessmentForm: ClinicalAssessmentFormActions.IClinicalAssessmentFormSaveActionCreator,
    createClinicalAssessmentForm: ClinicalAssessmentFormActions.IClinicalAssessmentFormCreateActionCreator,
    clearClinicalAssessmentForm: ClinicalAssessmentFormActions.IClinicalAssessmentFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleMessageModal: ClinicalAssessmentFormPageActions.IClinicalAssessmentFormPageToggleMessageModalActionCreator;
}

interface IClinicalAssessmentFormPageProps {

    form: Dtos.ClinicalAssessmentForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: IClinicalAssessmentReduxFormState;

    location: Location;
    match: match<IClinicalAssessmentFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    clinicalAssessmentForm: Dtos.ClinicalAssessmentForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingClinicalAssessmentForm: boolean;
    loadClinicalAssessmentFormSuccess: boolean;
    loadClinicalAssessmentFormFailure: boolean;

    savingClinicalAssessmentForm: boolean;
    saveClinicalAssessmentFormSuccess: boolean;
    saveClinicalAssessmentFormFailure: boolean;

    creatingClinicalAssessmentForm: boolean;
    createClinicalAssessmentFormSuccess: boolean;
    createClinicalAssessmentFormFailure: boolean;

    messageModalOpen: boolean;
}

type ClinicalAssessmentFormPageProps = IClinicalAssessmentFormPageProps & IClinicalAssessmentFormPageActions;

const formName: string = "ClinicalAssessmentForm";
const reduxFormName: string = "reduxForms.clinicalAssessmentForm";
const subTableInputLabelClass: string = 'col-form-label col-sm-4 pr-0';
const subTableInputFieldClass: string = 'col-sm-8';
const subTableInputGroupClass: string = 'row mb-4';

const questionLabelClassNames: string = 'col-form-label col-sm-9';
const answerClassNames: string = 'col-sm-3 px-4';

const subQuestionLabelClassNames: string = questionLabelClassNames + ' pl-12'

class ClinicalAssessmentFormPage extends React.PureComponent<ClinicalAssessmentFormPageProps, any> {

    constructor(props: ClinicalAssessmentFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearClinicalAssessmentForm = this.clearClinicalAssessmentForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadClinicalAssessmentForm,
            createClinicalAssessmentForm
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadClinicalAssessmentForm(identifier);
        }
        else if (!identifier) {
            createClinicalAssessmentForm();
        }
        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: ClinicalAssessmentFormPageProps) {
    }

    componentDidUpdate(prevProps: ClinicalAssessmentFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingClinicalAssessmentForm,
            loadClinicalAssessmentFormSuccess,
            loadClinicalAssessmentFormFailure,
            savingClinicalAssessmentForm,
            saveClinicalAssessmentFormSuccess,
            saveClinicalAssessmentFormFailure,
            creatingClinicalAssessmentForm,
            createClinicalAssessmentFormSuccess,
            createClinicalAssessmentFormFailure,
            createClinicalAssessmentForm,
            clinicalAssessmentForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            toggleMessageModal,

        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/pre-registration");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createClinicalAssessmentForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }


        if (!loadingClinicalAssessmentForm && prevProps.loadingClinicalAssessmentForm) {
            if (loadClinicalAssessmentFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingClinicalAssessmentForm && prevProps.savingClinicalAssessmentForm) {
            if (saveClinicalAssessmentFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    this.loadForm();
                }

                if (!identifier) {

                    loadPatientById(clinicalAssessmentForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingClinicalAssessmentForm && prevProps.creatingClinicalAssessmentForm) {
            if (createClinicalAssessmentFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: ClinicalAssessmentFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearClinicalAssessmentForm();
        loadForm(reduxFormName, initialClinicalAssessmentFormState);
        resetForm(reduxFormName);
    }

    render() {

        const {
            loadingPatient,
            savingClinicalAssessmentForm,
            loadingInstitution,
            loadingClinicalAssessmentForm,
            loadClinicalAssessmentFormSuccess,
            createClinicalAssessmentFormSuccess,
            creatingClinicalAssessmentForm
        } = this.props



        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingClinicalAssessmentForm || creatingClinicalAssessmentForm}
            saving={savingClinicalAssessmentForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadClinicalAssessmentFormSuccess,
            createClinicalAssessmentFormSuccess,
            saveClinicalAssessmentFormSuccess,
            reduxFormState,
            formState,
            messageModalOpen,
            toggleMessageModal,
            savingClinicalAssessmentForm,
            formProperties,
            changeForm
        } = this.props;

        
        const browser = detect();
        const isIe: boolean = browser.name == "ie";

        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingClinicalAssessmentForm;
        
        let group1: boolean = (patient && patient.registrationGroup != undefined && patient.registrationGroup == 0);
        let group2: boolean = (patient && patient.registrationGroup != undefined && patient.registrationGroup == 1);
       

        const {
            concomitantsDisabled,
            otherMalignancies,
            otherMalignanciesDiseaseFree,
            concomitantMedications,
        } = this.handleDisabledValues();

        const formComponents: { [index: string]: RegistrationFormComponent } = {
            performanceStatusHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3>Performance Status</h3>
                </div>
            },
            assessmentDate: {
                type: RegistrationComponent.RegistrationControl,
                inputType: DatePickerFormInputFabric,
                validationMessageAbove: true,
                inputDisabled: formDisabled,               
                inputProps: {
                    maxDate: new Date()
                },
            }, 
            ecog: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "ecog", lookups))
                }
            },
            priorCanceHistoryHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3>Prior Cancer History </h3>
                </div>
            },
            otherMalignanciesGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className={"form-group row d-block border border-bottom-1 border-top-0 no-gutter"}>
                    {
                        renderFormControls(
                            form,
                            "ClinicalAssessmentForm",
                            {
                                otherMalignancies: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: RadioButtonSetFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "otherMalignancies", lookups))
                                    },
                                    groupClassName: !otherMalignancies ? "border-0 d-flex pt-4 pb-4" : "border-0 d-flex pt-4",
                                }, 
                                otherMalignanciesTypeCarcinomaInSitu: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        label: getFormPropertyLabel(formName, "otherMalignanciesTypeCarcinomaInSitu", formProperties)
                                    },
                                    inputLabel: "Type (Select all that apply)",
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesTypeBasalCellCarcinoma: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        label: getFormPropertyLabel(formName, "otherMalignanciesTypeBasalCellCarcinoma", formProperties)
                                    },
                                    inputLabel: " ",
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesTypeSquamousCellCarcinoma: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        label: getFormPropertyLabel(formName, "otherMalignanciesTypeSquamousCellCarcinoma", formProperties)
                                    },
                                    inputLabel: " ",
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesTypeSuperficialTransitionalCellCarcinoma: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        label: getFormPropertyLabel(formName, "otherMalignanciesTypeSuperficialTransitionalCellCarcinoma", formProperties)
                                    },
                                    inputLabel: " ",
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesTypeOther: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        label: getFormPropertyLabel(formName, "otherMalignanciesTypeOther", formProperties)
                                    },
                                    inputLabel: " ",
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesDiseaseFree: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: RadioButtonSetFormInput,
                                    inputDisabled: formDisabled || !otherMalignancies,
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "otherMalignanciesDiseaseFree", lookups))
                                    },
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                otherMalignanciesPrimaryTreatmentCompleted: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: DatePickerFormInputFabric,
                                    inputDisabled: formDisabled || !otherMalignancies,               
                                    inputProps: {
                                        maxDate: new Date()
                                    },
                                    groupClassName: !otherMalignancies ? "hide-component" : "border-0 d-flex pt-4 pb-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                }, 
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm
                        )
                    }
                </div>
            },
            priorChemotherapyOrRecentCranialRadiationLastTwoYears: {
                type: RegistrationComponent.RegistrationControl,
                inputType: RadioButtonSetFormInput,
                inputDisabled: formDisabled || group2,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "priorChemotherapyOrRecentCranialRadiationLastTwoYears", lookups))
                }
            },
            concomitantMedicationHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3>Concomitant Medication</h3>
                </div>
            },
            concomitantMedications: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "concomitantMedications", lookups))
                }
            },
            concomitants: {
                type: RegistrationComponent.RegistrationGrid,
                formName: "ClinicalAssessmentFormConcomitant",
                disabled: formDisabled || !concomitantMedications,
                label: "Add Item",
                minRows: 1,
                className: !concomitantMedications ? "hide-component" : "sub-table-state mt-4 font-small",
                removeConfig: GridRemove.Last,
                addRow: (gridComponent, index, array) => {
                    const state: Dtos.ClinicalAssessmentFormConcomitant =
                        Object.assign(
                            { ...gridComponent.initialState }
                        );
                    return state;
                },
                columnStyles: [
                    { 
                        width: '24%'
                    },
                    {
                        width: '24%'
                    },
                    {
                        width: '24%'
                    },
                    {
                        width: '24%'
                    }
                ],
                components: {
                    medicationGroup: {
                        type: RegistrationComponent.RegistrationMultiFormControls,
                        label: "Medication",
                        component: GenericMultiFormGroup,
                        fieldClassName: "d-flex flex-column",
                        labelClassName: "form-col-label",
                        components: {
                            medicationName: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationType: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentFormConcomitant", "medicationType", lookups))
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationIndication: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentFormConcomitant", "medicationIndication", lookups))
                                },
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    if (value != 4) {
                                        changeForm(reduxFormName + parentModel + '.medicationIndicationOther', null);
                                    }
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationIndicationOther: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass,
                                disabledFn: (form: Dtos.ClinicalAssessmentFormConcomitant, arrayKey: string) => {
                                    if (form) {
                                        if (form.medicationIndication != 4) {
                                            return true;
                                        }
                                    }
                                    return false;
                                },
                            },
                        },
                    },
                    medicationPeriod: {
                        type: RegistrationComponent.RegistrationMultiFormControls,
                        label: "Medication Period",
                        component: GenericMultiFormGroup,
                        fieldClassName: "d-flex flex-column",
                        labelClassName: "form-col-label",
                        components: {
                            medicationStartDate: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: DatePickerFormInputFabric,
                                validationMessageAbove: true,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    maxDate: new Date()
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationStopDate: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: DatePickerFormInputFabric,
                                validationMessageAbove: true,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    maxDate: new Date()
                                },
                                disabledFn: (form: Dtos.ClinicalAssessmentFormConcomitant, arrayKey: string) => {
                                    if (form) {
                                        if (form.medicationOngoing != true) {
                                            return false;
                                        }

                                    }

                                    return true;
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationOngoing: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: CheckboxFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    label: ""
                                },
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);

                                    if (value != false) {
                                        changeForm(reduxFormName + parentModel + '.medicationStopDate', null);
                                    }
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass + ' pt-1',
                                groupClassName: subTableInputGroupClass
                            },
                        }
                    },
                    medicationDose: {
                        type: RegistrationComponent.RegistrationMultiFormControls,
                        label: "Medication Dose",
                        component: GenericMultiFormGroup,
                        fieldClassName: "d-flex flex-column",
                        labelClassName: "form-col-label",
                        components: {
                            medicationDose: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: NumberFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    maxValue: 9.99,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                                    precision: 2
                                },
                                softErrors: [
                                    {
                                        errorFunction: (value) => maxPrecisionTwo(value),
                                        errorMessage: errorMessages.PRECISION_TWO
                                    }
                                ],
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass 
                            },
                            medicationUnit: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentFormConcomitant", "medicationUnit", lookups))
                                },
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    if (value != 4) {
                                        changeForm(reduxFormName + parentModel + '.medicationUnitOther', null);
                                    }
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass 
                            },
                            medicationUnitOther: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                disabledFn: (form: Dtos.ClinicalAssessmentFormConcomitant, arrayKey: string) => {
                                    if (form) {
                                        if (form.medicationUnit != 4) {
                                            return true;
                                        }
                                    }
                                    return false;
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                        }
                    },
                    medicationDetailsGroup: {
                        type: RegistrationComponent.RegistrationMultiFormControls,
                        label: "Medication Details",
                        component: GenericMultiFormGroup,
                        fieldClassName: "d-flex flex-column",
                        labelClassName: "form-col-label",
                        components: {
                            medicationRoute: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentFormConcomitant", "medicationRoute", lookups))
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                            medicationFrequency: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: SelectFormInput,
                                inputDisabled: formDisabled,
                                inputProps: {
                                    options: generateOptionsFromLookup(findLookup("ClinicalAssessmentFormConcomitant", "medicationFrequency", lookups))
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass,  
                                changeAction: (model, value, form, parentModel) => {
                                    changeForm(model, value);
                                    if (value != 4) {
                                        changeForm(reduxFormName + parentModel + '.medicationFrequencyOther', null);
                                    }

                                },
                            },
                            medicationFrequencyOther: {
                                type: RegistrationComponent.RegistrationControl,
                                inputType: TextFormInput,
                                inputDisabled: formDisabled,
                                disabledFn: (form: Dtos.ClinicalAssessmentFormConcomitant, arrayKey: string) => {
                                    if (form) {
                                        if (form.medicationFrequency != 5) {
                                            return true;
                                        }

                                    }
                                    return false;
                                },
                                labelClassName: subTableInputLabelClass,
                                fieldClassName: subTableInputFieldClass,
                                groupClassName: subTableInputGroupClass
                            },
                        },
                    },
                },
                initialState: initialClinicalAssessmentFormConcomitantState
            },
            bloodTestsHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3>Blood Tests</h3>
                </div>
            },
            fullBloodCountDateGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "ClinicalAssessmentForm",
                            {
                                fullBloodCountDate: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: DatePickerFormInputFabric,
                                    validationMessageAbove: true,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        maxDate: new Date()
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                wbcCount: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: <span>x 10<sup>9</sup>/l</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                platelets: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 0,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: <span>x 10<sup>9</sup>/l</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                absoluteNeutrophils: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: <span>x 10<sup>9</sup>/l</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                lymphocytes: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: <span>x 10<sup>9</sup>/l</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                haemoglobin: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: "g/L"
                                    },
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            ureaElectrolytesCreatineDateGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "ClinicalAssessmentForm",
                            {

                                ureaElectrolytesCreatineDate: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: DatePickerFormInputFabric,
                                    validationMessageAbove: true,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        maxDate: new Date()
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                    disabledFn: (form: Dtos.ClinicalAssessmentForm, arrayKey: string) => {
                                        if (form) {
                                            if (form.ureaElectrolytesCreatineDateSameAsFBC != true) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                },
                                ureaElectrolytesCreatineDateSameAsFBC: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        label: "Same as FBC"
                                    },
                                    inputLabel: " ",
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value) {
                                            changeForm(reduxFormName + parentModel + '.ureaElectrolytesCreatineDate', null);
                                        }
                                    },
                                },
                                sodium: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: <span>mmol/L</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                potassium: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: <span>mmol/L</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                urea: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: <span>mmol/L</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                creatine: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: "μmol/L"
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                creatineULN: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 99,
                                        minValue: 0,
                                        addOnRight: "μmol/L"
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames + ' indent-sub-question-2',
                                    fieldClassName: answerClassNames
                                },
                                creatineClearance: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 0,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: "ml/min"
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames + ' indent-sub-question-2',
                                    fieldClassName: answerClassNames
                                },
                                creatineDeteriminedBy: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: SelectFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        options: generateOptionsFromLookup(findLookup("ClinicalAssessmentForm", "creatineDeteriminedBy", lookups))
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames + ' indent-sub-question-2',
                                    fieldClassName: answerClassNames
                                },
                                chloride: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: <span>mmol/L</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                                bicarbonate: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: NumberFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        precision: 1,
                                        maxValue: 999,
                                        minValue: 0,
                                        addOnRight: <span>mmol/L</span>
                                    },
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                    labelClassName: subQuestionLabelClassNames,
                                    fieldClassName: answerClassNames
                                },
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            },
            liverFunctionTestGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="form-group row d-block border border-bottom-1 border-top-0 no-gutter">
                    {
                        renderFormControls(
                            form,
                            "ClinicalAssessmentForm",
                            {
                                liverFunctionTestDate: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: DatePickerFormInputFabric,
                                    validationMessageAbove: true,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        maxDate: new Date()
                                    },
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                    disabledFn: (form: Dtos.ClinicalAssessmentForm, arrayKey: string) => {
                                        if (form) {
                                            if (form.liverFunctionTestDateSameAsFBC != true) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    }
                                },
                                liverFunctionTestDateSameAsFBC: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        label: "Same as FBC"
                                    },
                                    inputLabel: " ",
                                    groupClassName: "border-0 d-flex pt-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
                                        if (value) {
                                            changeForm(reduxFormName + parentModel + '.liverFunctionTestDate', null);
                                        }
                                    },
                                },
                                clinicalChemistryTable: {
                                    type: RegistrationComponent.RegistrationElement,
                                    component: <div className="px-4">
                                        <table className={classNames("table table-bordered bg-white mb-4 mt-4", { "fixed-table": isIe })}>
                                            <thead style={{ whiteSpace: "nowrap" }} className="thead-light">
                                                <tr>
                                                    <th>
                                                        Test
                                                    </th>
                                                    <th>
                                                        Value
                                                    </th>
                                                    <th>
                                                        ULN
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "alt", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    alt: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    },
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    altUln: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    },
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "alp", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {

                                                                    alp: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    }
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "ast", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    ast: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    }
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    astUln: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    }
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "totalBilirubin", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    totalBilirubin: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>μmol/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    }
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    totalInstitutionUln: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>μmol/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    }
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "albumin", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    albumin: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>g/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    },
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "ggt", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    ggt: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>U/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    },
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">
                                                        {
                                                            getFormPropertyLabel(formName, "totalProtein", formProperties)
                                                        }
                                                    </td>


                                                    <td className="align-middle">
                                                        {
                                                            renderFormControls(
                                                                form,
                                                                "ClinicalAssessmentForm",
                                                                {
                                                                    totalProtein: {
                                                                        type: RegistrationComponent.RegistrationControl,
                                                                        inputType: NumberFormInput,
                                                                        inputDisabled: formDisabled,
                                                                        inputProps: {
                                                                            precision: 1,
                                                                            maxValue: 999,
                                                                            minValue: 0,
                                                                            addOnRight: <span>g/L</span>
                                                                        },
                                                                        softErrors: [
                                                                            {
                                                                                errorFunction: (value) => maxPrecisionOne(value),
                                                                                errorMessage: errorMessages.PRECISION_ONE
                                                                            }
                                                                        ],
                                                                        removeContainer: true
                                                                    },
                                                                },
                                                                lookups,
                                                                validationFailures,
                                                                formProperties,
                                                                changeForm)
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                },
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm)
                    }
                </div>
            }
        };
        /*
        const formComponents: { [index: string]: RegistrationFormComponent } = {
            
            
            
        };
        */

        return <div>
            <h1>Clinical Assessment Form</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "clinicalAssessmentForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearClinicalAssessmentForm() {
        this.props.clearClinicalAssessmentForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Clinical Assessment Form"
            }
        ]
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    concomitantsRequired(data: Dtos.ClinicalAssessmentForm): boolean {
        var returnBool: boolean = false;
        var concomitants = data.concomitants;
        if (!concomitants || concomitants.length == 0)
        {
            returnBool = true; // Value required
        }
        /*
        pik3cactdnas.forEach(tl => {
            if (!tl.dateOfCollection)
                returnBool = true; // Value required

            if (!tl.dateReceivedAtCentralLaboratory)
                returnBool = true; // Value required

            if (!tl.dateAssayPik3cactdnaCompleted)
                returnBool = true; // Value required

            if (!tl.pik3cactdnaAssayResult)
                returnBool = true; // Value required
        });
        */

        return returnBool;
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        let otherMalignancies:boolean = false;
        if (form.otherMalignancies != undefined) {
            otherMalignancies = form.otherMalignancies == 0;
        }

        let otherMalignanciesDiseaseFree:boolean = false;
        if (otherMalignancies && form.otherMalignanciesDiseaseFree != undefined) {
            otherMalignanciesDiseaseFree = form.otherMalignanciesDiseaseFree == 0;
        }

        let concomitantMedications:boolean = false;
        if (form.concomitantMedications != undefined) {
            concomitantMedications = form.concomitantMedications == 1;
        }

        return {
            concomitantsDisabled: false,
            otherMalignancies,
            otherMalignanciesDiseaseFree,
            concomitantMedications
        }
    }


    handleOnSubmit(data: Dtos.ClinicalAssessmentForm) {

        const {
            saveClinicalAssessmentForm,
            institutionCode,
            identifier,
            validationFailures,
            changeForm

        } = this.props;

        if (data && institutionCode) {

            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.ClinicalAssessmentForm = {} as Dtos.ClinicalAssessmentForm;


            //if (!isDisabled.concomitantsDisabled && this.concomitantsRequired(data)) { 
            //    Alert.warning(<NotificationAlert
            //        minWidth={500}
            //        alertContent={<div>Form <strong>not</strong> saved. Prior treatments must be reported. </div>}
            //        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
            //    />);
            //}
            //else {
                saveClinicalAssessmentForm(Object.assign({ ...data }, changes), institutionCode, identifier);
            //}
        }

    }


    handleOnSubmitFailed(data: Dtos.ClinicalAssessmentForm) {

        const { institutionCode,
            identifier,
        } = this.props;

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props

        // eslint-disable-next-line
        toggleMessageModal;
    }

    loadForm() {
        const {
            loadForm,
            clinicalAssessmentForm
        } = this.props

        let extraData: Dtos.ClinicalAssessmentForm = {} as Dtos.ClinicalAssessmentForm;

        loadForm(reduxFormName, Object.assign({ ...clinicalAssessmentForm }, extraData));
    }
}

const mapStateToProps = (state: IFigState, ownProps: ClinicalAssessmentFormPageProps): IClinicalAssessmentFormPageProps => {

    const clinicalAssessmentForm = !(state.registrationForms.clinicalAssessmentForms.formData instanceof Array) ? state.registrationForms.clinicalAssessmentForms.formData : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        clinicalAssessmentForm: clinicalAssessmentForm,
        lookups: state.registrationForms.clinicalAssessmentForms.lookups,
        loadingClinicalAssessmentForm: state.registrationForms.clinicalAssessmentForms.loadState &&
            (state.registrationForms.clinicalAssessmentForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.clinicalAssessmentForms.loadState.status == RequestState.None &&
                    state.registrationForms.clinicalAssessmentForms.createState.status == RequestState.None)),
        loadClinicalAssessmentFormSuccess: state.registrationForms.clinicalAssessmentForms.loadState && state.registrationForms.clinicalAssessmentForms.loadState.status == RequestState.Success,
        loadClinicalAssessmentFormFailure: state.registrationForms.clinicalAssessmentForms.loadState && state.registrationForms.clinicalAssessmentForms.loadState.status == RequestState.Failure,

        savingClinicalAssessmentForm: state.registrationForms.clinicalAssessmentForms.saveState && state.registrationForms.clinicalAssessmentForms.saveState.status == RequestState.Pending,
        saveClinicalAssessmentFormSuccess: state.registrationForms.clinicalAssessmentForms.saveState && state.registrationForms.clinicalAssessmentForms.saveState.status == RequestState.Success,
        saveClinicalAssessmentFormFailure: state.registrationForms.clinicalAssessmentForms.saveState && state.registrationForms.clinicalAssessmentForms.saveState.status == RequestState.Failure,

        creatingClinicalAssessmentForm: state.registrationForms.clinicalAssessmentForms.createState && state.registrationForms.clinicalAssessmentForms.createState.status == RequestState.Pending,
        createClinicalAssessmentFormSuccess: state.registrationForms.clinicalAssessmentForms.createState && state.registrationForms.clinicalAssessmentForms.createState.status == RequestState.Success,
        createClinicalAssessmentFormFailure: state.registrationForms.clinicalAssessmentForms.createState && state.registrationForms.clinicalAssessmentForms.createState.status == RequestState.Failure,

        form: state.reduxForms.clinicalAssessmentForm,
        formState: state.registrationForms.clinicalAssessmentForms.formState,
        formProperties: state.registrationForms.clinicalAssessmentForms.formProperties,

        reduxFormState: state.reduxForms.formState.clinicalAssessmentForm,
        validationFailures: clinicalAssessmentForm && clinicalAssessmentForm.id ?
            state.registrationForms.clinicalAssessmentForms.validationFailures :
            undefined,

        messageModalOpen: state.clinicalAssessmentFormPage.messageModalOpen
    };
};

const mapDispatchToProps = (dispatch): IClinicalAssessmentFormPageActions => {
    return {
        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadClinicalAssessmentForm: bindActionCreators(ClinicalAssessmentFormActions.LoadClinicalAssessmentFormByPatientIdentifier, dispatch),
        saveClinicalAssessmentForm: bindActionCreators(ClinicalAssessmentFormActions.SaveClinicalAssessmentForm, dispatch),
        createClinicalAssessmentForm: bindActionCreators(ClinicalAssessmentFormActions.CreateClinicalAssessmentForm, dispatch),
        clearClinicalAssessmentForm: bindActionCreators(ClinicalAssessmentFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleMessageModal: bindActionCreators(ClinicalAssessmentFormPageActions.toggleMessageModal, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(ClinicalAssessmentFormPage);
