import './styles/app.scss';
import 'react-dates/initialize';
import "core-js";
import "media-match/media.match"

import * as ReactDOM from 'react-dom';
import * as React from 'react';
// import registerServiceWorker from './registerServiceWorker';
import * as Dtos from './dtos/Fig.dtos';
import * as AuthenticationHelpers from './helpers/authentication';

import { initializeIcons } from '@uifabric/icons';


import { bindActionCreators } from "redux";
import { Provider, connect } from "react-redux";
import { Route, Switch, Redirect, match } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import { routerActions } from 'react-router-redux';

import { createBrowserHistory } from 'history';

import reducers, { IFigState } from './reducers/';

import * as UserActions from './actions/user';

import { configureStore } from "./store/";
import { rootEpic } from "./epics/";
import {
    RegistrationPage,
    PatientSummaryPage,
    PreRegistrationFormPage,
    GlioblastomaDetailFormPage,
    DemographicDetailFormPage,
    ClinicalAssessmentFormPage,
    PatientReportedFormPage,
    EligibilityCriteriaFormPage,
    SourceDocumentsFormPage,
    WebsiteSettingsPage,
    ErrorPage,
    SaePage,
    SaeViewPage,
    SaeNotifyPage,
} from "./containers/index";

import Alert from 'react-s-alert';
import { RequestState } from './enumerations/RequestState';
import AuthenticatedRoute from './components/common/Authentication/AuthenticatedRoute';
import { IInstitutionCodeParams } from './interfaces/params/routeParams';
import AuthenticatedFn from './components/common/Authentication/AuthenticatedFn';
import { OverlayLoading } from './components/common';
import * as AuthorisationDefinitions from './constants/authorisationDefinitions';
import * as AuthenticationHelper from './helpers/authentication';

import * as Tracking from './helpers/analytics';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

const reducer = reducers;

export const browserHistory = createBrowserHistory();

Tracking.initGa(browserHistory);
export const store = configureStore(reducer, rootEpic, browserHistory);

interface IAppActions {
    loadUser?: UserActions.IUserLoadActionCreator;
    navigate?: Function;
}

interface IAppProps {

    user?: Dtos.User;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;

    checkedAuthentication?: boolean;
    match?: match<any>
}

type AppProps = IAppProps & IAppActions;

interface IAuthenticatedRouteProps {
    user?: Dtos.User;
    location?: Location;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;
    checkedAuthentication?: boolean;
    match?: match<any>;
}

export default class App extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const {
            user,
            loadUser,
            loadingUser,
            loadUserSuccess,
            loadUserFailure,
            checkedAuthentication,
            match

        } = this.props

        if (!user && loadUser) {
            loadUser();
        }
    }

    render() {

        const {
            user,
            navigate,
            loadingUser,
            loadUserFailure,
            loadUserSuccess,
            checkedAuthentication,
            match
        } = this.props

        const showWebsiteSettings = user && AuthenticationHelper.isTrogOrSuperUser(user);

        const AuthenticatedRouteProps: IAuthenticatedRouteProps = {
            user: user,
            location: window.location,
            loadingUser: loadingUser,
            loadUserSuccess: loadUserSuccess,
            loadUserFailure: loadUserFailure,
            checkedAuthentication: checkedAuthentication,
            match: this.props.match

        }
        return <div>
            <ConnectedRouter history={browserHistory}>
            <Switch>
                    {
                        // Error Route
                    }
                    <Route path="/error/:errorCode" component={ErrorPage} />
                    {
                        // website settings Route
                    }
                    { showWebsiteSettings && (
                        <Route path="/settings" component={WebsiteSettingsPage} />
                    )}
                    {
                        // Patient Form Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/pre-registration-form" component={PreRegistrationFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/pre-registration-form" component={PreRegistrationFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/glioblastoma-detail-form" component={GlioblastomaDetailFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/demographic-detail-form" component={DemographicDetailFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/clinical-assessment-form" component={ClinicalAssessmentFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/patient-reported-form" component={PatientReportedFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/eligibility-criteria-form" component={EligibilityCriteriaFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/source-documents-form" component={SourceDocumentsFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    {
                        //Patient Summary Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/patient-summary" component={PatientSummaryPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <Route
                        exact
                        path="/registration/:institutionCode/:identifier"
                        render={
                            props => {
                                return <Redirect to={'/registration/' + props.match.params.institutionCode + '/' + props.match.params.identifier + '/patient-summary'} />
                            }
                        }
                    />
                    {
                        // SAE Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/sae/:institutionCode/notify-sae/trial-user/:trialUserType/sae/:saeId" component={SaeNotifyPage} authFn={AuthenticationHelpers.createSaeNotifyAuthorisationDefinition}  />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/sae/:institutionCode/view-sae/:saeId" component={SaeViewPage} authFn={AuthenticationHelpers.createSaeAuthorisationDefinition} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/sae/:institutionCode" component={SaePage} authFn={AuthenticationHelpers.createSaeAuthorisationDefinition} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/sae/" component={SaePage} authFn={AuthenticationHelpers.createSaeAuthorisationDefinition} />
                    {
                        //Registration Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} navigate={navigate} path="/registration/:institutionCode" component={RegistrationPage} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration" component={RegistrationPage} />

                    <AuthenticatedFn {...AuthenticatedRouteProps}>
                        {
                            (user, authenticationHelpers) => {
                                //debugger;
                                if (
                                    user &&
                                    AuthenticationHelpers.isExclusivelyInvestigator(user)) {

                                    return <Redirect from="*" to="/sae" />
                                }
                                else if (user) {
                                    return <Redirect from="*" to="/registration" />
                                }
                                else {
                                    window.location.href = "/auth/openiddict?ReturnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
                                    return <OverlayLoading />;
                                }
                            }
                        }
                    </AuthenticatedFn>
                </Switch>
            </ConnectedRouter>
            <Alert stack={{ limit: 5 }} html={false} effect='scale' position='bottom-right' timeout={5000} />
            </div>
    
        /*
        return (<div>
            <ConnectedRouter history={browserHistory}>
                <Switch>
                    {
                        // Error Route
                    }
                    <Route path="/error/:errorCode" component={ErrorPage} />
                    {
                        // Patient Form Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/pre-registration-form" component={PreRegistrationFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/pre-registration-form" component={PreRegistrationFormPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    {
                        //Patient Summary Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration/:institutionCode/:identifier/patient-summary" component={PatientSummaryPage} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} />
                    <Route
                        exact
                        path="/registration/:institutionCode/:identifier"
                        render={
                            props => {
                                return <Redirect to={'/registration/' + props.match.params.institutionCode + '/' + props.match.params.identifier + '/patient-summary'} />
                            }
                        }
                    />
                    {
                        //Registration Page Routes
                    }
                    <AuthenticatedRoute {...AuthenticatedRouteProps} createAuthorisationDefinitions={(path, location, user, match: match<IInstitutionCodeParams>) => { return AuthenticationHelpers.createAuthorisationDefinitionByInstitutionCode(path, location, user, match) }} navigate={navigate} path="/registration/:institutionCode" component={RegistrationPage} />
                    <AuthenticatedRoute {...AuthenticatedRouteProps} navigate={navigate} path="/registration" component={RegistrationPage} />

                    <AuthenticatedFn {...AuthenticatedRouteProps}>
                        {
                            (user, authenticationHelpers) => {
                                if (user) {
                                    return <Redirect from="*" to="/registration" />
                                }
                                else {
                                    window.location.href = "/auth/identity-server?continue=" + encodeURIComponent(window.location.pathname + window.location.search);
                                    return <OverlayLoading />;
                                }
                            }
                        }
                    </AuthenticatedFn>
                </Switch>
            </ConnectedRouter>
            <Alert stack={{ limit: 5 }} html={false} effect='scale' position='bottom-right' timeout={5000} />
        </div>)
    
                        */
    }
}


const mapStateToProps = (state: IFigState, ownProps: AppProps): IAppProps => {

    return {
        user: state.user.data,
        loadingUser: state.user.loadState && state.user.loadState.status == RequestState.Pending,
        loadUserSuccess: state.user.loadState && state.user.loadState.status == RequestState.Success,
        loadUserFailure: state.user.loadState && state.user.loadState.status == RequestState.Failure,
        checkedAuthentication: state.user.checked,
        match: ownProps.match
    };
};

const mapDispatchToProps = (dispatch): IAppActions => {
    return {
        loadUser: bindActionCreators(UserActions.LoadUser, dispatch),
        navigate: bindActionCreators(routerActions.push, dispatch)

    }
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)
/*
function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(
        <Provider store={store as any}>
            <ConnectedApp />
        </Provider>,
        document.getElementById('app')
    );
}
*/

ReactDOM.render(
    <Provider store={store as any}>
            <ConnectedApp />
        </Provider>,
    document.getElementById('app'));

//renderApp();

//registerServiceWorker();
